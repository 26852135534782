// https://cdn.integrations.muzzley.com/car-makers/car_full_details1573163473.067627.json

const routes = {
    register: '/v3/applications/:id/user-create',
    login: '/v3/applications/:id/login',
    resend: '/v3/applications/:id/resend-verification',
    recover: '/v3/applications/:id/reset-password',
    reset: '',
    users: '/v3/users',
    integrations: '/v3/managers',
    integrationCreate: '/v3/applications/:id/manager-create',
    integrationEdit: '/v3/managers/:id/manager-update',
    integrationTeamMembers: '/v3/managers/:id/teams/main/members',
    integrationTeamMemberInvite: '/v3/managers/:id/teams/main/invite',
    integrationTeamMemberPause: '/v3/managers/:id/teams/main/members/:user/pause',
    integrationTeamMemberResume: '/v3/managers/:id/teams/main/members/:user/resume',
    integrationTeamMemberDelete: '/v3/managers/:id/teams/main/members/:user',
    integrationClasses: '/v3/classes',
    integrationSchemas: '/v3/schemas',
    integrationUnits: '/units/units_table.json',
    integrationGetDevices: '/v3/managers/:id/channel-templates',
    integrationDeviceCRUD: '/v3/managers/:id/channel-templates/:channel-template-id',
    integrationCreateDevice: '/v3/managers/:id/create-channel-template',
    integrationCreateDeviceComponent:
        '/v3/managers/:id/channel-templates/:channel-template-id/create-component',
    integrationDeviceInfo: '/v3/managers/:id/channel-templates/:channel-template-id/retrieve-specs',
    integrationDeviceComponentCRUD:
        '/v3/managers/:id/channel-templates/:channel-template-id/merge-component-specs',
    products: '/v3/applications/self/insurance-products',
    projects: '/v3/applications',
    projectCreate: '/v3/applications/:id/application-create',
    projectEdit: '/v3/applications/:id/application-update',
    projectUploadImage: '/v3/applications/:id/asset-create',
    projectGetDocuments: '/v3/applications/:id/product-documents',
    projectDocumentsCRUD: '/v3/applications/:id/product-documents-update',
    teamMembers: '/v3/applications/:id/teams/main/members',
    teamMemberInvite: '/v3/applications/:id/teams/main/invite',
    teamMemberPause: '/v3/applications/:id/teams/main/members/:user/pause',
    teamMemberResume: '/v3/applications/:id/teams/main/members/:user/resume',
    teamMemberDelete: '/v3/applications/:id/teams/main/members/:user',
    endUsers: '/v3/applications/:id/users',
    endUsersSearch: '/v3/applications/:id/user-search',
    events: 'https://jsonblob.com/api/jsonBlob/08371213-f8a1-11e8-806d-958d1dc0b566',
    devices: 'https://jsonblob.com/api/jsonBlob/e04d6cdf-f96a-11e8-9f04-ef422e554bb3',
    flowTester: 'https://jsonblob.com/api/jsonBlob/01e1485b-f96e-11e8-9f04-7db960dab5d2',
    assetUpload: '/v3/applications/:id/asset-create',
    assets: '/v3/applications/:id/assets',
    assetsDetete: '/v3/applications/:id/assets/:asset',
    assetsDownload: '/v3/applications/:id/asset-token-generate',
    assetsDownloadBulk: '/v3/applications/:id/asset-bulk-token-generate',
    whiteLabelSubmitBuild: '/v3/applications/:id/whitelabel-build-generate',
    whitelabels: '/v3/whitelabels',
    builds: '/v3/applications/:id/whitelabel-builds',
    compatibleDevices: '/v3/applications/:id/available-channel-templates',
    myCompatibleDevices: '/v3/users/:id/available-channel-templates',
    generatePrivateDevice: '/v3/applications/:id/available-channel-template-generate',
    enabledDevices: '/v3/applications/:id/channel-templates',
    deviceAssets: '/v3/channel-templates/:id/asset-schemas',
    deviceAssetsCreate: '/v3/applications/:id/channel-template-create',
    deviceAssetsEdit: '/v3/applications/:id/channel-template-change',
    deviceAssetsDelete: '/v3/applications/:id/channel-templates/:channel-template-id',
    deviceAssetsUpload: '/v3/channel-templates/:id/asset-create',
    metaData: '/v3/utils/meta-data',
    dataViews: '/v3/data-views/pages/:namespace/execute',
    dataMeta: '/v3/data-views/pages/:namespace/meta',
    usecases: '/v3/applications/:id/usecases',
    usecaseCRUD: '/v3/applications/:id/usecases/:usecase-id',
    usecaseState: '/v3/applications/:id/usecases/:usecase-id/state',
    usecaseClone: '/v3/applications/:id/usecases/:usecase-id/clone',
    usecaseAsset: '/v3/usecases/:id/asset-create',
    usecaseActivate: '/v3/usecases/:id/generate-rules',
    usecaseDeactivate: '/v3/usecases/:id/remove-rules',
    insuranceInsuree: '/v3/applications/:id/detailed-view',
    insuranceInsureesSearch: '/v3/applications/:id/insurees-search',
    insuranceContextFinder: '/v3/applications/:id/context-finder',
    insurancePoliciesSearch: '/v3/applications/:id/policies-search',
    insuranceMarkPolicyAsCanceled: '/v3/applications/:id/policies/:policy-id/mark-as-canceled',
    insuranceMarkPolicyAsState: '/v3/applications/:id/policies/:policy-id/mark-as-:state',
    insuranceClaimsSearch: '/v3/applications/:id/claims-search',
    insuranceQuotesSearch: '/v3/applications/:id/quotes-search',
    insuranceAssetsSearch: '/v3/applications/:id/protected-assets-search',
    insurancePaymentsDocumentsSearch: '/v3/applications/:id/payments-search',
    // here
    // insuranceCarSpecs: '/car-makers/car_full_details1573163473.067627.json',
    insuranceCarSpecs: '/segments/cars/car-makes/car_full_details1573163473.067627_all_lite.json',
    insuranceEditQuoteAsset:
        '/v3/applications/:id/quotes/:quote-id/protected-assets/:protected-asset-id/properties-update',
    insuranceSubscriptionPayments: '/v3/applications/:id/subscriptions/:subscription-id/payments',
    insuranceCreateClaim: '/v3/applications/:id/policies/:policy-id/create-claim',
    insurnanceUpdateClaimStatus: '/v3/applications/:id/claims/:claim-id/:claim-mark-as',
    insuranceGetAllClaimPossibleStatuses: '/v3/applications/:id/meta',
    insuranceGetAllPolicyPossibleStatuses: '/v3/applications/:id/meta',
    insurnanceUpdateClaimProperties:
        '/v3/applications/:id/claims/:claim-id/properties/:property-id/data-update',
    insuranceGetClaimData: '/v3/applications/:id/claims/:claim-id',
    insuranceGetClaimProperties: '/v3/applications/:id/claims/:claim-id/properties',
    insuranceGetClaimChatHistory: '/v3/applications/:id/claims/:claim-id/history',
    insuranceClaimChatHistoryCreateMessage: '/v3/applications/:id/claims/:claim-id/history-create',
    insuranceClaimChatHistoryCreateAsset: '/v3/applications/:id/claims/:claim-id/create-assets',
    insuranceClaimChatHistoryDownloadAsset:
        '/v3/applications/:id/claims/:claim-id/assets/:asset-id',
    insuranceAssetEditOdometer:
        '/v3/applications/:id/quotes/:quote-id/protected-assets/:protected-asset-id/report-mark-as-approved',
    insuranceAssetRejectOdometerImage:
        '/v3/applications/:id/quotes/:quote-id/protected-assets/:protected-asset-id/report-mark-as-rejected',
    insuranceAssetEditOdometerReport:
        'https://insurance-demo-application.:env.muzzley.com/v3/inbox',
    insuranceAssetOdometerHistory:
        '/v3/applications/:id/quotes/:quote-id/protected-assets/:protected-asset-id/odometer-history',
    insuranceOdometerHistoryTotalDriven:
        '/v3/applications/:id/channels/:channel-id/components/:component-namespace/properties/:property-namespace/value',
    insuranceGetPayments: '/v3/applications/:id/subscriptions/:subscription-id/payments',
    insuranceUpdatePayment: '/v3/applications/:id/payments/:payment-id/add-transaction',
    insuranceUploadPaymentDocument: '/v3/applications/:id/payments/:payment-id/create-assets',
    insuranceDeletePaymentDocument:
        '/v3/applications/:id/subscriptions/:subscription-id/assets/:asset-id',
    insuranceAssetEditEligible:
        '/v3/applications/:id/quotes/:quote-id/protected-assets/:protected-asset-id/properties-update',
    paymentGateways: '/v3/applications/:id/payment-gateways',
    countries: '/v3/countries',
    countryByNamespace: '/v3/countries/:namespace',
    currencies: '/v3/currencies',
    appFileUpload: '/v3/applications/:id/asset-upload',
    networksDirectories: '/v3/directories',
    networksDirectoryCRUD: '/v3/directories/:id',
    networksDirectoryEntities: '/v3/directories/:id/entities',
    networksDirectoryServices: '/v3/directories/:id/offered-services',
    networksDirectoryItems: '/v3/directories/:id/offered-items',
    networksEntityCRUD: '/v3/directories/:id/entities/:entity-id',
    networksServiceCRUD: '/v3/directories/:id/offered-services/:service-id',
    networksItemCRUD: '/v3/directories/:id/offered-items/:item-id',
    networksSegments: '/v3/segments',
    networksCategories: '/v3/categories',
    networksSegmentsCreate: '/v3/directories/:id/:type/:element-id/create-segments',
    networksSegmentsUpdate: '/v3/directories/:id/:type/:element-id/update-segments',
    networksCategoriesCreate: '/v3/directories/:id/:type/:element-id/create-categories',
    networksCategoriesUpdate: '/v3/directories/:id/:type/:element-id/update-categories',
    networksGetDirectoryMembers: '/v3/directories/:id/teams/main/members',
    networksGetDirectoryMembersInvite: '/v3/directories/:id/teams/main/invite',
    networksGetDirectoryMembersPause: '/v3/directories/:id/teams/main/members/:member-id/pause',
    networksGetDirectoryMembersResume: '/v3/directories/:id/teams/main/members/:member-id/resume',
    networksGetDirectoryMembersDelete: '/v3/directories/:id/teams/main/members/:member-id',
    networksGetNetworkMembers: '/v3/:type/:id/teams/main/members',
    networksGetMembersInvite: '/v3/:type/:id/teams/main/invite',
    networksGetMembersPause: '/v3/:type/:id/teams/main/members/:member-id/pause',
    networksGetMembersResume: '/v3/:type/:id/teams/main/members/:member-id/resume',
    networksGetMembersDelete: '/v3/:type/:id/teams/main/members/:member-id',
    networksNetworks: '/v3/networks',
    networksNetwork: '/v3/networks/:id',
    networksNetworkEntities: '/v3/networks/:id/entities',
    networksNetworkUpdateDirectories: '/v3/networks/:id/update-directories',
    networksNetworkUpdateSegments: '/v3/networks/:id/update-segments',
    networksNetworkUpdateEntities: '/v3/networks/:id/update-entities',
    networksNetworkAvailableEntities: '/v3/networks/:id/available-entities',
    networksNetworkAssociateEntities: '/v3/networks/:id/associate-entities',
    networksNetworkEntityAvailableServices: '/v3/networks/:id/available-offered-services',
    networksNetworkEntityServices: '/v3/networks/:id/entities/:entity-id/offered-services',
    networksNetworkEntityAvailableItems: '/v3/networks/:id/available-offered-items',
    networksNetworkEntityItems: '/v3/networks/:id/entities/:entity-id/offered-items',
    networksNetworkAssociateEntityServices:
        '/v3/networks/:id/entities/:entity-id/associate-offered-services',
    networksNetworkAssociateEntityItems:
        '/v3/networks/:id/entities/:entity-id/associate-offered-items',
    networksDirectoriesById: '/v3/networks/:id/directories',
    templatesGetServices: '/v3/applications/:id/services',
    templatesServicesAddService: '/v3/applications/:id/add-service',
    templatesServicesGetAndUpdateService: '/v3/applications/:id/services/:service-id',
    templatesServicesRemoveService: '/v3/applications/:id/remove-service',
    templatesServicesMarkAsEnableDisable: '/v3/applications/:id/services/:service-id/:mark-as',
    templatesServicesRequiredAssets:
        '/v3/applications/:id/services/:service-id/required-protected-assets',
    templatesServicesOptionalAssets:
        '/v3/applications/:id/services/:service-id/optional-protected-assets',
    templatesServicesAddAssets:
        '/v3/applications/:id/services/:service-id/add-protected-asset-property-spec',
    templatesServicesDeleteAssets:
        '/v3/applications/:id/services/:service-id/protected-asset-property-specs/:spec-id',
    templatesServicesEditAssets:
        '/v3/applications/:id/services/:service-id/protected-asset-property-specs/:spec-id',
    templatesServicesAssetMarkAs:
        '/v3/applications/:id/services/:service-id/protected-asset-property-specs/:spec-id/:mark-as',
    templatesServicesBoardItemReorder:
        '/v3/applications/:id/services/:service-id/protected-asset-property-specs-update',
    templatesServicesGetCoverages: '/v3/applications/:id/services/:service-id/:type',
    templatesServicesAvailableCoverages:
        '/v3/applications/:id/services/:service-id/available-coverages',
    templatesServicesItemReorderByType: '/v3/applications/:id/services/:service-id/:type',
    templatesServicesCoverageMarkAs:
        '/v3/applications/:id/services/:service-id/coverage-property-specs/:spec-id/:mark-as',
    templatesServicesItemMarkAsBytype:
        '/v3/applications/:id/services/:service-id/:type/:spec-id/:mark-as',
    templatesServiesGetCoverage:
        '/v3/applications/:id/services/:service-id/coverage-specs/:spec-id',
    templatesServiesAddCoverage: '/v3/applications/:id/services/:service-id/add-coverage-specs',
    templatesServiesEditCoverage: '/v3/applications/:id/services/:service-id/update-coverage-specs',
    templatesServicesRemoveItem: '/v3/applications/:id/services/:service-id/:spec/:spec-id',
    templatesServicesGetSettings: '/v3/applications/:id/services/:service-id/settings',
    templatesServicesEditSettings: '/v3/applications/:id/services/:service-id/update-settings',
    templatesGetCards: '/v3/applications/:id/card-spec-assets',
    templatesCreateCard: '/v3/applications/:id/add-card-spec-assets',
    templatesDeleteCard: '/v3/applications/:id/card-spec-assets/:spec-id',
    templatesUpdateCard: '/v3/applications/:id/card-spec-assets/:spec-id/update',
    templatesReorderCards: '/v3/applications/:id/order-card-spec-assets',
    businessRuleEngineGenerate: '/v3/applications/:id/node-red-build-generate',
    businessRuleEngineRetrieve: '/v3/applications/:id/retrieve-node-red-config',
    appMeta: '/v3/applications/:id/meta',
    distributorsGetMyDistributorsRequests: '/v3/insurers/:id/distributor-requests',
    distributorsGetMyDistributorsRequestsHistory:
        '/v3/insurers/:id/distributor-requests/:request-id/history',
    distributorsSetMyDistributorRequest: '/v3/insurers/:id/distributor-requests/:request-id/:state',
    distributorsGetInsurerApplications: '/v3/applications/self/insurer-applications',
    distributorsGetInsuranceProductsRequests: '/v3/distributors/:id/insurance-requests',
    distributorsSetInsuranceProductsRequest: '/v3/distributors/:id/insurance-requests',
    distributorsChangeInsuranceProductRequest:
        '/v3/distributors/:id/insurance-requests/:request-id/:state',
    distributorsSellersInvites: '/v3/distributors/:id/seller-invites',
    distributorsSellerInvite: '/v3/distributors/:id/seller-invite',
    distributorsGetMeta: '/v3/distributors/:id/meta',
    providersGetMyProvidersRequests: '/v3/insurers/:id/provider-requests',
    providersGetMyProvidersRequestsHistory:
        '/v3/insurers/:id/provider-requests/:request-id/history',
    providersSetMyProviderRequest: '/v3/insurers/:id/provider-requests/:request-id/:state',
    providersGetInsurerApplications: '/v3/applications/self/insurer-applications',
    providersGetInsuranceProductsRequests: '/v3/providers/:id/insurance-requests',
    providersSetInsuranceProductsRequest: '/v3/providers/:id/insurance-requests',
    providersChangeInsuranceProductRequest:
        '/v3/providers/:id/insurance-requests/:request-id/:state',
    providersSellersInvites: '/v3/providers/:id/seller-invites',
    providersSellerInvite: '/v3/providers/:id/seller-invite',
    providersGetMeta: '/v3/providers/:id/meta',

    insurers: '/v3/insurers',
    adminGetApprovalRequests: '/v3/applications/:id/account-approval-requests',
    adminSetApprovalRequest: '/v3/applications/:id/:type/:type-id/:state',
    adminGetFinancialEntities: '/v3/applications/self/financial-entities',
    adminCreateFinancialEntity: '/v3/applications/self/financial-entities',
    adminGetFinancialEntity: '/v3/applications/self/financial-entities/:entity-id',
    adminGetSalesChannels: '/v3/applications/:id/quote-specs',
    adminGetDistributors: '/v3/applications/self/distributors',
    adminGetDistributor: '/v3/applications/self/distributors/:distributor-id',
    adminGetProviders: '/v3/applications/self/providers',
    adminGetProvider: '/v3/applications/self/providers/:provider-id',
    adminCreateFinancialBreakdown: '/v3/applications/self/financial-breakdowns',
    adminGetFinancialBreakdowns: '/v3/applications/self/financial-breakdowns',
    adminGetFinancialBreakdown: '/v3/applications/self/financial-breakdowns/:id',
    adminGetFinancialFlowAttributionOperations:
        '/v3/applications/self/financial-flow-attribution-operations',
    adminGetFinancialPrecalculationOperations:
        '/v3/applications/self/financial-precalculation-operations',
    adminUpdateFinancialBreakdownFlows:
        '/v3/applications/self/financial-breakdowns/:id/update-flows',
    adminDeleteFinancialBreakdownFlows:
        '/v3/applications/self/financial-breakdowns/:id/delete-flows',
    adminUpdateFinancialBreakdownPrecalculations:
        '/v3/applications/self/financial-breakdowns/:id/update-precalculations',
    adminDeleteFinancialBreakdownPrecalculations:
        '/v3/applications/self/financial-breakdown-flows/:id/delete-precalculations',
    projectsGetProjectInsurersProducts: '/v3/insurers/:id/products',
    projectGetProjectInsurersProducts: '/v3/insurers/:id/products/:app-id',
    projectEnableInsurersProduct: '/v3/insurers/:id/applications/:app-id/enable-as-product',
    projectDisableInsurersProduct: '/v3/insurers/:id/applications/:app-id/disable-as-product',
    projectUpdateAccessInsurersProduct: '/v3/insurers/:id/products/:app-id/update-access',
    settingsLegalDisclaimers: '/v3/applications/:id/legal-disclaimers',
    settingsUpdateLegalDisclaimers: '/v3/applications/:id/update-legal-disclaimers',
    settingsGetUpdateDeleteLegalDisclaimer: '/v3/applications/:id/legal-disclaimers/:disclaimer-id',
    settingsBulkUpdateLegalDisclaimers: '/v3/applications/:id/legal-disclaimers-bulk-update',
    settingsUpsertCountry: '/v3/applications/:id/update-country',
    settingsUpsertCurrency: '/v3/applications/:id/update-currency',
    sellersInvites: '/v3/applications/:id/seller-invites',
    sellersInvitesWebviewRedirect:
        '/v3/applications/:id/seller-invites/:invite-id/webview-redirect',
    comissionsView: '/v3/applications/self/financial-breakdowns/:id/commissions-view',
    comissionsCSV: '/v3/applications/self/financial-breakdowns/:id/commissions-export-csv',
    comissionsCreatePayment:
        '/v3/applications/self/financial-breakdowns/:financialbreakdown_id/commissions-create-payment',
    comissionsUpdatePayment:
        '/v3/applications/self/financial-breakdowns/:financialbreakdown_id/commissions-payment-history/:paymenthistory_id/update-payment',
    distributorGetAuthorizedProducts: '/v3/distributors/:id/authorized-products',
    distributorCreateDistributorPoint: '/v3/distributors/:id/create-distribution-point',
    distributorListPoints: '/v3/distributors/:id/distribution-points?point_type=flag',
    distributorListLeadsPoints:
        '/v3/distributors/:id/distribution-points?point_type=lead_generation',
    distributorListLeadsGeneratorsPoints:
        '/v3/distributors/:id/distributor-sellers?seller_type=lead_generator',
    distributorListSellerInsuranceAgent:
        '/v3/distributors/:id/distributor-sellers?seller_type=insurance_agent',
    distributorUpdateDistributionPoint:
        '/v3/distributors/:id/distribution-points/:distributionpoint_id/update',
    distributorMarkPointAsState:
        '/v3/distributors/:id/distribution-points/:distributionpoint_id/mark-as-{state}',
    distributorSellerInvite: '/v3/distributors/:id/distributorseller-invite',
    distributorUpdateDistributionSellerPoint:
        '/v3/distributors/:id/distributor-sellers/:distributorseller_id/update-distribution-points',
    distributorUpdateDistributionSellerProduct:
        '/v3/distributors/:id/distributor-sellers/:distributorseller_id/update-authorized-products',
    distributorUpdateDistributorSeller:
        '/v3/distributors/:id/distributor-sellers/:distributorseller_id/update',
    distributorRetrieveDistributionSellerInfo:
        '/v3/distributors/:id/distributor-sellers/:distributorseller_id',
    distributorRemoveDistributionSellerProduct:
        '/v3/distributors/:id/distributor-sellers/:distributorseller_id/remove-distribution-points',
    distributorRemoveAuthorizedProduct:
        '/v3/distributors/:id/distributor-sellers/:distributorseller_id/remove-authorized-products',
    distributorSellerMarkPointAsState:
        '/v3/distributors/:id/distributor-sellers/:distributorseller_id/mark-as-{state}',
    listDistributorSellersAuthorizedProducts: '/v3/distributor-sellers/self/authorized-products',
    listDistributorSellerOutlets: '/v3/distributor-sellers/self/distribution-points',
    retrieveRegisterDistributorInfo: '/v3/applications/self/distributors/:distributor_id',
    updateRegisterDistributorInfo: '/v3/applications/self/distributors/:distributor_id/update',
    submitRegisterDistributorInfo: '/v3/applications/self/distributors/:distributor_id/submit',
    distributorMarkAsState: 'v3/applications/:id/distributors/:distributor-id/mark-as-{state}',
    distributorsMetaData: '/v3/distributors/:id/meta',

    providerGetAuthorizedProducts: '/v3/providers/:id/authorized-products',
    providerCreateProviderPoint: '/v3/providers/:id/create-distribution-point',
    providerListPoints: '/v3/providers/:id/distribution-points?point_type=flag',
    providerListLeadsPoints: '/v3/providers/:id/distribution-points?point_type=lead_generation',
    providerListLeadsGeneratorsPoints:
        '/v3/providers/:id/provider-sellers?seller_type=lead_generator',
    providerListSellerInsuranceAgent:
        '/v3/providers/:id/provider-sellers?seller_type=insurance_agent',
    providerUpdateDistributionPoint:
        '/v3/providers/:id/distribution-points/:distributionpoint_id/update',
    providerMarkPointAsState:
        '/v3/providers/:id/distribution-points/:distributionpoint_id/mark-as-{state}',
    providerSellerInvite: '/v3/providers/:id/providerseller-invite',
    providerUpdateDistributionSellerPoint:
        '/v3/providers/:id/provider-sellers/:providerseller_id/update-distribution-points',
    providerUpdateDistributionSellerProduct:
        '/v3/providers/:id/provider-sellers/:providerseller_id/update-authorized-products',
    providerUpdateProviderSeller: '/v3/providers/:id/provider-sellers/:providerseller_id/update',
    providerRetrieveDistributionSellerInfo: '/v3/providers/:id/provider-sellers/:providerseller_id',
    providerRemoveDistributionSellerProduct:
        '/v3/providers/:id/provider-sellers/:providerseller_id/remove-distribution-points',
    providerRemoveAuthorizedProduct:
        '/v3/providers/:id/provider-sellers/:providerseller_id/remove-authorized-products',
    providerSellerMarkPointAsState:
        '/v3/providers/:id/provider-sellers/:providerseller_id/mark-as-{state}',
    listproviderSellersAuthorizedProducts: '/v3/provider-sellers/self/authorized-products',
    listProviderSellerOutlets: '/v3/provider-sellers/self/distribution-points',

    retrieveRegisterProviderInfo: '/v3/applications/self/providers/:provider_id',
    updateRegisterProviderInfo: '/v3/applications/self/providers/:provider_id/update',
    submitRegisterProviderInfo: '/v3/applications/self/providers/:provider_id/submit',
    providerMarkAsState: 'v3/applications/:id/providers/:provider-id/mark-as-{state}',
    providersMetaData: '/v3/providers/:id/meta',

    smsEntry: '/v3/applications/self/distributors/:distributor-id/sms-entry',
    smsEntryProvider: '/v3/applications/self/providers/:provider-id/sms-entry',
    smsVerify: '/v3/applications/self/distributors/:distributor-id/sms-verify',
    smsVerifyProvider: '/v3/applications/self/providers/:provider-id/sms-verify',
    distributorsGetAssets: '/v3/applications/self/distributors/:distributor-id/assets',
    distributorsGetAssetsFilteredByDistributorsEntityDocumentsOnly:
        '/v3/applications/self/distributors/:distributor-id/assets?entity=distributors',
    distributorsGetAssetsFilteredByDistributorsRequestsEntityDocumentsOnly:
        '/v3/applications/self/distributors/:distributor-id/assets?entity=distributor-requests&entity_id=:distributorrequest_id',
    providersGetAssets: '/v3/applications/self/providers/:provider-id/assets',
    providersGetAssetsFilteredByProvidersEntityDocumentsOnly:
        '/v3/applications/self/providers/:provider-id/assets?entity=providers',
    providersGetAssetsFilteredByProvidersRequestsEntityDocumentsOnly:
        '/v3/applications/self/providers/:provider-id/assets?entity=provider-requests&entity_id=:providerrequest_id',

    distributorGetProducts: '/v3/applications/:id/distributors/:distributor-id/available-products',
    distributorAddProducts: '/v3/applications/self/distributors/:distributor_id/add-products',
    distributorGetProductConfiguration:
        '/v3/applications/self/distributor-requests/:entity-id/configurations',
    distributorSetProductConfiguration:
        '/v3/applications/self/distributor-requests/:entity-id/add-configurations',
    distributorSetDocumentConfiguration:
        '/v3/applications/self/distributor-requests/:entity-id/documents-update',
    updateProtectedAssetsData:
        '/v3/applications/self/quotes/:quote_id/protected-assets/[:protected-asset-id]/properties-update',
    updateInsureeData: '/v3/applications/:product-id/insurees/:insuree-id/properties-update',
    updateProtectedAssetData:
        '/v3/applications/:application_id/quotes/:quote_id/protected-assets/[:protected-asset-id]/properties-update',
    updateClaimData:
        '/v3/applications/:application_id/claims/:claim_id/properties/:property_id/data-update',
    getProductPolicyStatesReasons:
        '/v3/i18n/applications/self/applications/:id/configurations/policy_state_reasons',
    distributorsSellersMeta: 'v3/applications/self/users/me/meta',
    me: '/v3/users/me',
    impersonate: '/v3/users/:id',
    getUserEndpoints: '/v3/users/:id/tokens?fields=endpoints&page_size=1&order_by=-created',
    adminGetInsurerInfo: '/v3/applications/self/insurer-applications/:id',
    adminGetFinancialClientsTypes: '/v3/applications/self/financial-client-types?order_by=name',
    adminGetFinancialClients: '/v3/applications/self/financial-clients?type=:type',
    createClaimAsset: '/v3/applications/:project-id/claims/:claim-id/create-assets',
    getClaimAssets: '/v3/applications/:project-id/claims/:claim-id/assets',
    updatePortalType: '/v3/applications/:project-id/update-portal-type',
    updateDistributionDefault: '/v3/applications/:project-id/update-distribution-default',
    distributorInviteDistributorsGetInvites: '/v3/applications/self/distributors/:dist_id/invites',
    distributorInviteDistributorsGetUniqueInvite:
        '/v3/applications/self/distributors/:dist_id/invites/:invite_id',
    distributorInviteDistributorInvite: '/v3/applications/self/invite-distributor',
    createApplicationDistributorAssets: '/v3/applications/self/distributors/:distributor-id/assets',
    createApplicationProviderAssets: '/v3/applications/self/providers/:provider-id/assets',
    networksGetProviders: '/v3/applications/self/providers',

    distributorUpdateDistributorInvite: '/v3/applications/self/distributor-invites/:id/update',
    createCampaign: '/campaigns',
    editCampaign: '/campaigns/:id',
    voucherGetCampaigns: '/campaigns',
    voucherGetCampaign: '/campaigns/:id',
    getBatches: '/batches',
    globalSearch: '/search',
    getVouchersByBatch: '/campaigns/:campaign_id/batches/:batch_id/vouchers',
    getBatchesByCampaign: '/campaigns/:campaign_id/batches',
    downloadBatch: '/batches?batch_id=:batch_id&download=true',
    createVoucherBatchWithPayment: '/batches',
    putVouchers: '/vouchers/:voucher_id',
    // putVouchers2: '/campains/:campaign_id/batches/:batch_id/vouchers/:voucher_id',
    updateCreatedClaimProperties:
        '/v3/applications/:application-id/claims/:claim-id/properties-update',
};
export default routes;
