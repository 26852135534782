import { getText } from 'localization';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Styles from './Styles.module.scss';
import './Styles.scss';

type Props = {
    dateFilter: {
        startDate: any;
        endDate: any;
    };
    onChange: (date: any) => void;
    onClose?: () => void;
};

// https://react-dates.github.io/react-dates/?path=/story/daypickerrangecontroller--default
const InputDatePicker: React.FC<Props> = ({ dateFilter, onChange, onClose }) => {
    const [startDate, setStartDate] = useState(dateFilter?.startDate || null);
    const [endDate, setEndDate] = useState(dateFilter?.endDate || null);
    const [focusedInput, setFocusedInput] = useState(null);

    const dateFormat = 'DD/MM/YYYY';

    const onDatesChange = ({ startDate, endDate }) => {
        if (!endDate) {
            endDate = moment();
        }
        onChange({
            startDate,
            endDate,
        });
        setStartDate(startDate);
        setEndDate(endDate);
        setFocusedInput(null);
    };

    // Cleanup date filter when it is removed
    useEffect(() => {
        if (!dateFilter) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [dateFilter]);

    return (
        <div className={Styles.container} data-test="InputDatePickerContainer">
            <div className={Styles.title}>
                {getText('index_insurance_policies_advanced_search_start_date_label')}:
            </div>
            <div className={Styles.datePicker}>
                <DateRangePicker
                    displayFormat={() => 'DD/MM/YYYY'}
                    isOutsideRange={() => false}
                    startDate={startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    startDatePlaceholderText={getText('vouchers_select_start_date_label')}
                    endDatePlaceholderText={getText('vouchers_select_end_date_label')}
                    showClearDates={true}
                    showDefaultInputIcon={true}
                    inputIconPosition="after"
                    onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                    monthFormat={dateFormat}
                    // Event handlers
                    onDatesChange={onDatesChange ? onDatesChange : () => { }} // PropTypes.func.isRequired,
                    onClose={onClose ? onClose : () => { }}
                />
            </div>
        </div>
    );
};

export default InputDatePicker;
