import { getCurrentLanguage, getText } from 'localization';
import type { Steps } from 'pages/Campaigns/Create/types';
import { Emission } from 'pages/EmitVouchers/types';
export const API_URL_PREFIX = 'https://api.platform';
export const API_URL_SUFIX = 'habit.io';
/*  */
export const API_DEFAULT_TIMEOUT = 60000;
export const TEN_MINUTES_IN_MILLISECONDS = 600000;
export const HUNDRED_ITEMS = 100;
export const API_SWR_DEFAULT_REVALIDATE = 5000;
export const BUGSNAG_API_KEY = 'd22d39496dd31647b272eedff8010616';
export const RECAPTCHA_KEY = '6Lcc6n8UAAAAAEKVZeAuphIAj22NF7LQECJLdH5y';
export const DEFAULT_PAGE_SIZE = 999999;
export const DEFAULT_SHOW_SEARCH_ON_LIST = 10;
export const DEFAULT_ANALYTICS_DATE_PICKER_OFFSET = 0;
export const DEFAULT_TOAST_ALERT_TIME = 5000;
export const DEFAULT_ALERT_ERROR_MESSAGE = getText('common_something_went_wrong');
export const DEFAULT_TOOLTIP_SHOW_DELAY = 100;
export const INT_INTEGRATION_DEVICE_RECIPE_ID = '4a258868-23c4-11e8-a906-ef1cd2ea6b2b';
export const STG_INTEGRATION_DEVICE_RECIPE_ID = 'bcb088ca-25e9-11e8-bbf7-6e8e8fac3ec8';
export const QA_INTEGRATION_DEVICE_RECIPE_ID = '9df71ea9-e5d4-4e9b-8c4e-295a0453314c';
export const PRD_INTEGRATION_DEVICE_RECIPE_ID = 'e2492ca2-33b4-11e9-af22-000d3a2c0f58';
export const DATE_FORMAT_DEFAULT = 'dd/MM/yyyy, HH:mm:ss';
export const DATE_FORMAT_NO_TIME_DEFAULT = 'dd/MM/yyyy';
export const DATE_FORMAT_INTERNATIONAL = 'yyyy-MM-dd, HH:mm:ss';
export const DATE_FORMAT_NO_TIME_INTERNATIONAL = 'yyyy-MM-dd';
export const DATE_FORMAT_EUROPEAN = 'dd-MM-yyyy, HH:mm:ss';
export const DATE_FORMAT_NO_TIME_EUROPEAN = 'dd-MM-yyyy';
export const INSURANCE_MANDATORY_PROPERTY_CLASS = 'io.habit.operations.fields.required';
export const INSURANCE_MANDATORY_COVERAGE_PROPERTY_CLASS = 'io.habit.coverages.mandatory';
export const INSURANCE_EXCLUDED_PROPERTY_CLASS = 'io.habit.platform.internal';
export const INSURANCE_MEDIA_PARAM_PROPERTY_CLASS = 'io.habit.trackableassets.type.{param}';
export const INSURANCE_MEDIA_PHOTO_PROPERTY_CLASS = 'io.habit.trackableassets.type.photo';
export const INSURANCE_MEDIA_VIDEO_PROPERTY_CLASS = 'io.habit.trackableassets.type.video';
export const INSURANCE_MEDIA_DOCUMENT_PROPERTY_CLASS = 'io.habit.trackableassets.type.document';
export const INSURANCE_MEDIA_VIDEO_URI_SEARCH = `{token}`;
export const INSURANCE_PRIVATE_FILE_URI_SEARCH = `{token}`;
export const INSURANCE_TOTALS_BY_TYPE_MAP = {
    insurees: 'total_number_of_insurees',
    policies: 'total_number_of_policies',
    claims: 'total_number_of_claims',
    quotes: 'total_number_of_quotes',
    assets: 'total_number_of_protectedassets',
};
export const NETWORK_MAX_NUM_OF_ITEMS = 200;
export const SEGMENT_LIST = [
    {
        id: 'car',
        text: getText('segment_list_constants_car_label'),
        value: 'car',
    },
    {
        id: 'home',
        text: getText('segment_list_constants_home_label'),
        value: 'home',
    },
    {
        id: 'pet',
        text: getText('segment_list_constants_pet_label'),
        value: 'pet',
    },
    {
        id: 'sport',
        text: getText('segment_list_constants_sport_label'),
        value: 'sport',
    },
    {
        id: 'phone',
        text: getText('segment_list_constants_phone_label'),
        value: 'phone',
    },
];
export const GENDER_LIST = [
    {
        id: 'male',
        text: 'Male',
        value: 'male',
    },
    {
        id: 'female',
        text: 'Female',
        value: 'female',
    },
    {
        id: 'other',
        text: 'Other',
        value: 'other',
    },
];
export const MARITAL_STATUS_LIST = [
    {
        id: 'married',
        text: 'Married',
        value: 'married',
    },
    {
        id: 'single',
        text: 'Single',
        value: 'single',
    },
    {
        id: 'divorced',
        text: 'Divorced',
        value: 'divorced',
    },
    {
        id: 'widowed',
        text: 'Widowed',
        value: 'widowed',
    },
    {
        id: 'other',
        text: 'Other',
        value: 'other',
    },
];
export const INSURANCE_STATUS_LIST = [
    {
        id: 'active',
        text: getText('insurance_status_active'),
        value: 'active',
    },
    {
        id: 'inactive',
        text: getText('insurance_status_inactive'),
        value: 'inactive',
    },
    {
        id: 'pending',
        text: getText('insurance_status_pending'),
        value: 'pending',
    },
    {
        id: 'canceled',
        text: getText('insurance_status_cancelled'),
        value: 'canceled',
    },
    {
        id: 'suspended',
        text: getText('insurance_status_suspended'),
        value: 'suspended',
    },
    {
        id: 'terminated',
        text: getText('insurance_status_terminated'),
        value: 'terminated',
    },
    {
        id: 'renewed',
        text: getText('insurance_status_renewed'),
        value: 'renewed',
    },
];
export const INSURANCE_CLAIM_STATUS_LIST = [
    {
        id: 'submitted',
        text: getText('general_state_utils_constants_submitted_label'),
        value: 'submitted',
    },
    {
        id: 'processing',
        text: getText('general_state_utils_constants_processing_label'),
        value: 'processing',
    },
    {
        id: 'pending',
        text: getText('general_state_utils_constants_pending_label'),
        value: 'pending',
    },
    {
        id: 'approved',
        text: getText('general_state_utils_constants_approved_label'),
        value: 'approved',
    },
    {
        id: 'rejected',
        text: getText('general_state_utils_constants_rejected_label'),
        value: 'rejected',
    },
    {
        id: 'closed',
        text: getText('general_state_utils_constants_closed_label'),
        value: 'closed',
    },
    {
        id: 'custom',
        text: getText('general_state_utils_constants_custom_label'),
        value: 'custom',
    },
];
export const MEMBER_PERMISSIONS_LIST = [
    {
        id: 'true',
        text: 'Can view',
        icon: 'icon_password_on',
        value: 'true',
    },
    {
        id: 'false',
        text: 'Can edit',
        icon: 'icon_edit',
        value: 'false',
    },
];
export const NETWORK_ENTITIES_SELECT_OPTIONS = [
    {
        id: 'all',
        text: 'All',
        value: 'all',
    },
    {
        id: 'none',
        text: 'None',
        value: 'none',
    },
];
export const NETWORK_ENTITY_PRICE_DISCOUNT_SELECT_OPTIONS = [
    {
        id: 'fee',
        text: 'Price',
        value: 'fee',
    },
    {
        id: 'discount',
        text: 'Discount',
        value: 'discount',
    },
];
export const INSURANCE_CLAIM_STATUS = [
    {
        id: 'submitted',
        text: getText('insurance_claim_status_submitted'),
        value: 'submitted',
    },
    {
        id: 'processing',
        text: getText('insurance_claim_status_processing'),
        value: 'processing',
    },
    {
        id: 'pending',
        text: getText('insurance_claim_status_pending'),
        value: 'pending',
    },
    {
        id: 'approved',
        text: getText('insurance_claim_status_approved'),
        value: 'approved',
    },
    {
        id: 'rejected',
        text: getText('insurance_claim_status_rejected'),
        value: 'rejected',
    },
    {
        id: 'closed',
        text: getText('insurance_claim_status_closed'),
        value: 'closed',
    },
    {
        id: 'custom',
        text: getText('insurance_claim_status_custom'),
        value: 'custom',
    },
];
export const INSURANCE_CLAIM_PAYABLE_TO = [
    {
        id: 'insurer',
        text: 'Insurer',
        value: 'insurer',
    },
    {
        id: 'provider',
        text: 'Provider',
        value: 'provider',
    },
];

export const INSURANCE_CLAIM_STATUS_BY_TYPE = {
    submitted: [
        {
            id: 'processing',
            text: getText('insurance_claim_status_by_type_submitted_processing'),
            value: 'processing',
        },
        {
            id: 'pending',
            text: getText('insurance_claim_status_by_type_submitted_pending'),
            value: 'pending',
        },
        {
            id: 'approved',
            text: getText('insurance_claim_status_by_type_submitted_approved'),
            value: 'approved',
        },
        {
            id: 'rejected',
            text: getText('insurance_claim_status_by_type_submitted_rejected'),
            value: 'rejected',
        },
    ],
    processing: [
        {
            id: 'pending',
            text: getText('insurance_claim_status_by_type_processing_pending'),
            value: 'pending',
        },
        {
            id: 'approved',
            text: getText('insurance_claim_status_by_type_processing_approved'),
            value: 'approved',
        },
        {
            id: 'rejected',
            text: getText('insurance_claim_status_by_type_processing_rejected'),
            value: 'rejected',
        },
    ],
    pending: [
        {
            id: 'approved',
            text: getText('insurance_claim_status_by_type_pending_approved'),
            value: 'approved',
        },
        {
            id: 'rejected',
            text: getText('insurance_claim_status_by_type_pending_rejected'),
            value: 'rejected',
        },
    ],
    approved: [
        {
            id: 'pending',
            text: getText('insurance_claim_status_by_type_approved_pending'),
            value: 'pending',
        },
        {
            id: 'rejected',
            text: getText('insurance_claim_status_by_type_approved_rejected'),
            value: 'rejected',
        },
    ],
    rejected: [
        {
            id: 'pending',
            text: getText('insurance_claim_status_by_type_rejected_pending'),
            value: 'pending',
        },
        {
            id: 'approved',
            text: getText('insurance_claim_status_by_type_rejected_approved'),
            value: 'approved',
        },
    ],
};
export const INSURANCE_CLAIM_CHAT_SEND_OPTIONS = {
    message: {
        action: 'insurance_message',
        entity: 'ClaimHistory',
        entity_id: null,
        classes: ['io.habit.claim.chat.message'],
    },
    asset: {
        action: 'insurance_message',
        entity: 'ClaimAssets',
        entity_id: null,
        classes: ['io.habit.claim.asset'],
    },
};
export const OBD_VALUES_BY_ENV = {
    integrations: {
        component_namespace: 'component_name__f8251b5f',
        property_cost_month: 'cost_month_a0819059',
        property_distance_month: 'distance_month_b56bbc7d',
        property_total_distance_driven: 'total_distance_driven_53c1ad0a',
    },
    staging: {
        component_namespace: 'component_name__4189ec8e',
        property_cost_month: 'cost_month_5bbb7a54',
        property_distance_month: 'property_name__cc31e28f',
        property_total_distance_driven: 'total_distance_driven_77acdccc',
    },
    qa: {
        component_namespace: 'pay_as_you_drive_f6bbd201',
        property_cost_month: 'cost_month_99938ea0',
        property_distance_month: 'distance_month_bd7e2a73',
        property_total_distance_driven: 'total_distance_driven_ced2f6df',
    },
    production: {
        component_namespace: 'pay_as_you_drive_f0b669cd',
        property_cost_month: 'total_cost_this_month_e60b32d9',
        property_distance_month: 'distance_driven_this_month_48ffc95a',
        property_total_distance_driven: 'total_distance_driven_abc87d96',
    },
};
export const TEMPLATES_SERVICES_SEGMENTS_NAMESPACES = {
    car: ['v1_car_payd'],
    pets: ['v1_pets_health'],
    home: [],
};
export const TEMPLATES_SETTINGS_NAMESPACES = [
    'tracking_method',
    'payment_gateway',
    'payment_frequency',
];
export const TEMPLATES_CARD_TYPE = [
    {
        id: 'guest',
        text: 'Guest',
        value: 'guest',
    },
    {
        id: 'welcome',
        text: 'Welcome',
        value: 'welcome',
    },
    {
        id: 'persistent',
        text: 'Persistent',
        value: 'persistent',
    },
    {
        id: 'activation-success-card',
        text: 'Success activation',
        value: 'activation-success-card',
    },
    {
        id: 'location-permission-card',
        text: 'Location permission',
        value: 'location-permission-card',
    },
];
export const TEMPLATES_CARD_BUTTON_TYPE = [
    {
        id: 'browse',
        text: 'Browse',
        value: 'browse',
    },
    {
        id: 'email',
        text: 'Email',
        value: 'email',
    },
    {
        id: 'dismiss',
        text: 'Dismiss',
        value: 'dismiss',
    },
    // {
    //     id: 'gotoStage',
    //     text: 'Go to Stage',
    //     value: 'gotoStage',
    // },
];
export const ANALYTICS_USER_FACTS_OPTIONS = [
    {
        id: 'events',
        text: 'Nº of Location Events',
        value: 'events',
    },
    {
        id: 'users',
        text: 'Total users joined',
        value: 'users',
    },
];
export const DISTRIBUTOR_REQUEST_ACTIONS = {
    pending: ['reject', 'approve'],
    rejected: ['pending'],
    approved: ['pending'],
};
export const MY_DISTRIBUTORS_STATUS_LIST = [
    {
        id: 'pending',
        text: getText('my_distributors_status_list_pending'),
        value: 'pending',
    },
    {
        id: 'approved',
        text: getText('my_distributors_status_list_approved'),
        value: 'approved',
    },
    {
        id: 'rejected',
        text: getText('my_distributors_status_list_rejected'),
        value: 'rejected',
    },
];

export const MY_PROVIDERS_STATUS_LIST = [
    {
        id: 'pending',
        text: getText('my_distributors_status_list_pending'),
        value: 'pending',
    },
    {
        id: 'approved',
        text: getText('my_distributors_status_list_approved'),
        value: 'approved',
    },
    {
        id: 'rejected',
        text: getText('my_distributors_status_list_rejected'),
        value: 'rejected',
    },
];

export const ADMIN_REQUESTS_STATUS_LIST = [
    {
        id: 'pending',
        text: getText('admin_requests_status_list_pending'),
        value: 'pending',
    },
    {
        id: 'approved',
        text: getText('admin_requests_status_list_approved'),
        value: 'approved',
    },
    {
        id: 'rejected',
        text: getText('admin_requests_status_list_rejected'),
        value: 'rejected',
    },
];
export const ADMIN_REQUESTS_TYPE_LIST = [
    {
        id: 'insurer',
        text: 'Insurer',
        value: 'insurer',
    },
    {
        id: 'distributor',
        text: 'Distributor',
        value: 'distributor',
    },
];

export const ENGINES = [
    {
        id: 'custom_bre',
        text: 'Custom BRE',
        value: 'custom_bre',
    },
    {
        id: 'selfcare',
        text: 'Selfcare',
        value: 'selfcare',
    },
];

export const BASE_AMOUNTS = [
    {
        id: 'full_amount',
        text: 'Full amount',
        value: 'full_amount',
    },
];

export const CHANNELS = [
    {
        id: 'app',
        text: 'App',
        value: 'app',
    },
    {
        id: 'webview',
        text: 'Webview',
        value: 'webview',
    },
    {
        id: 'other',
        text: 'Other',
        value: 'other',
    },
];

export const INFINITY_AUTOCLOSE = 999999999999999;

export const CUSTOM_BRE_ADMIN_REQUIRED_VALUES = [
    {
        financialentity_type: getText('financial_flow_target_financial_entity_type'),
    },
    {
        target_financialentity_id: getText('financial_flow_target_financial_entity'),
    },
    {
        sales_channels: getText('financial_flow_sales_channels'),
    },
    {
        engine: getText('financial_flow_engine'),
    },
];

export const NON_CUSTOM_BRE_ADMIN_REQUIRED_VALUES = [
    {
        sales_channels: getText('financial_flow_sales_channels'),
    },
    {
        engine: getText('financial_flow_engine'),
    },
    {
        financialentity_type: getText('financial_flow_target_financial_entity_type'),
    },
    {
        amount_type: getText('financial_flow_amount_type'),
    },
    {
        value: getText('financial_flow_attribution_value'),
    },
    {
        attributionoperation_id: getText('financial_flow_attribution_operation'),
    },
    {
        source_financialentity_id: getText('financial_flow_source_financial_entity'),
    },
    {
        target_financialentity_id: getText('financial_flow_target_financial_entity'),
    },
];

export const NEW_FINANCIAL_FLOW_CUSTOM_BRE_ADMIN_REQUIRED_VALUES = [
    {
        target_type: getText('financial_flow_target_financial_entity_type'),
    },

    {
        engine: getText('financial_flow_engine'),
    },
];

export const NEW_FINANCIAL_FLOW_NON_CUSTOM_BRE_ADMIN_REQUIRED_VALUES = [
    {
        engine: getText('financial_flow_engine'),
    },
    {
        amount_type: getText('financial_flow_amount_type'),
    },
    {
        value: getText('financial_flow_attribution_value'),
    },
    {
        attributionoperation_id: getText('financial_flow_attribution_operation'),
    },
];

export const NEW_FINANCIAL_FLOW_REQUIRED_VALUES = [
    {
        source_type: getText('financial_flow_source_financial_entity_type'),
    },
    {
        target_type: getText('financial_flow_target_financial_entity_type'),
    },
];

export const ADMIN_FINANCIAL_ENTITIES_TYPE_LIST = [
    {
        id: 'main_distributor',
        text: 'Main Distributor',
        value: 'main_distributor',
    },
    {
        id: 'distribution_partner',
        text: 'Distribution partner',
        value: 'distribution_partner',
    },
    {
        id: 'payment_gateway',
        text: 'Payment Gateway',
        value: 'payment_gateway',
    },
    {
        id: 'underwriter',
        text: 'Underwriter',
        value: 'underwriter',
    },
    {
        id: 'platform',
        text: 'Platform',
        value: 'platform',
    },
    {
        id: 'other',
        text: 'Other',
        value: 'other',
    },
];
export const ADMIN_FINANCIAL_ENTITIES_PAYOUT_METHOD_LIST = [
    {
        id: 'blocked',
        text: 'Blocked',
        value: 'blocked',
    },
    {
        id: 'manual',
        text: 'Manual',
        value: 'manual',
    },
    {
        id: 'automatic',
        text: 'Automatic',
        value: 'automatic',
    },
];

export const ADMIN_FINANCIAL_ENTITIES_PAYMENT_METHOD_LIST = [
    {
        id: 'sepa',
        text: 'SEPA',
        value: 'sepa',
    },
];

export const ADMIN_FINANCIAL_ENTITIES_SUPPORTED_COUNTRIES_LIST = [
    {
        id: 'pt',
        text: 'Portugal',
        value: 'pt',
    },
    {
        id: 'br',
        text: 'Brasil',
        value: 'br',
    },
];

export const DISTRIBUTOR_COMMISSIONS_AMOUNT_LIST = [
    {
        id: '5000',
        text: '5000 €',
        value: '5000',
    },
    {
        id: '10000',
        text: '10000 €',
        value: '10000',
    },
    {
        id: '20000',
        text: '20000 €',
        value: '20000',
    },
];
export const DOCUMENTS_SECTIONS_LIST = [
    {
        id: 'at_product_info',
        text: 'Info',
        value: 'at_product_info',
    },
    {
        id: 'at_offer',
        text: 'Offer',
        value: 'at_offer',
    },
    {
        id: 'at_subscription',
        text: 'Subscription',
        value: 'at_subscription',
    },
    {
        id: 'at_privacy_policy_consent',
        text: 'Privacy policy',
        value: 'at_privacy_policy_consent',
    },
];

export const PROVIDER_DOCUMENTS_SECTIONS_LIST = [
    // {
    //     id: 'platform_registration',
    //     text: 'Platform registration',
    //     value: 'platform_registration',
    // },
    {
        id: 'provision_agreement',
        text: getText('providers_provision_agreement'),
        value: 'provision_agreement',
    },
    // {
    //     id: 'commercial_conditions',
    //     text: 'Commercial conditions',
    //     value: 'commercial_conditions',
    // },
    // {
    //     id: 'other',
    //     text: 'Other',
    //     value: 'other',
    // },
];

export const DISTRIBUTOR_DOCUMENTS_SECTIONS_LIST = [
    // {
    //     id: 'platform_registration',
    //     text: 'Platform registration',
    //     value: 'platform_registration',
    // },
    {
        id: 'distribution_agreement',
        text: getText('distributor_documents_section_list_distribution_agreement_label'),
        value: 'distribution_agreement',
    },
    // {
    //     id: 'commercial_conditions',
    //     text: 'Commercial conditions',
    //     value: 'commercial_conditions',
    // },
    // {
    //     id: 'other',
    //     text: 'Other',
    //     value: 'other',
    // },
];
export const INSURANCE_PAYMENTS_STATUS_LIST = [
    {
        id: 'pending',
        text: getText('insurance_payments_status_list_pending'),
        value: 'pending',
    },
    {
        id: 'received',
        text: getText('insurance_payments_status_list_received'),
        value: 'received',
    },
    {
        id: 'sent_for_settle',
        text: getText('insurance_payments_status_list_sent_for_settle'),
        value: 'sent_for_settle',
    },
    {
        id: 'settled',
        text: getText('insurance_payments_status_list_settled'),
        value: 'settled',
    },
    {
        id: 'refunded',
        text: getText('insurance_payments_status_list_refunded'),
        value: 'refunded',
    },
    {
        id: 'canceled',
        text: getText('insurance_payments_status_list_cancelled'),
        value: 'canceled',
    },
    {
        id: 'error',
        text: getText('insurance_payments_status_list_error'),
        value: 'error',
    },
    {
        id: 'authorized',
        text: getText('insurance_payments_status_list_authorized'),
        value: 'authorized',
    },
    {
        id: 'new',
        text: getText('insurance_payments_status_list_new'),
        value: 'new',
    },
    {
        id: 'processing',
        text: getText('insurance_payments_status_list_processing'),
        value: 'processing',
    },
    {
        id: 'refused',
        text: getText('insurance_payments_status_list_refused'),
        value: 'refused',
    },
    {
        id: 'expired',
        text: getText('insurance_payments_status_list_expired'),
        value: 'expired',
    },
    {
        id: 'sent_for_cancelation',
        text: getText('insurance_payments_status_list_sent_for_cancelation'),
        value: 'sent_for_cancelation',
    },
    {
        id: 'sent_for_refund',
        text: getText('insurance_payments_status_list_sent_for_refund'),
        value: 'sent_for_refund',
    },
    {
        id: 'chargeback_received',
        text: getText('insurance_payments_status_list_chargeback_received'),
        value: 'chargeback_received',
    },
    {
        id: 'chargeback',
        text: getText('insurance_payments_status_list_chargeback'),
        value: 'chargeback',
    },
];
export const READONLY_PAYMENT_STATUS_LIST = [
    {
        id: 'paid',
        text: getText('insurance_payments_status_list_paid'),
        value: 'paid',
    },
];

export const INSURANCE_PAYMENTS_TYPE_LIST = [];
export const MONTHS_LIST = [
    {
        id: 1,
        value: 1,
        name: getText('datepicker_january'),
    },
    {
        id: 2,
        value: 2,
        name: getText('datepicker_february'),
    },
    {
        id: 3,
        value: 3,
        name: getText('datepicker_march'),
    },
    {
        id: 4,
        value: 4,
        name: getText('datepicker_april'),
    },
    {
        id: 5,
        value: 5,
        name: getText('datepicker_may'),
    },
    {
        id: 6,
        value: 6,
        name: getText('datepicker_june'),
    },
    {
        id: 7,
        value: 7,
        name: getText('datepicker_july'),
    },
    {
        id: 8,
        value: 8,
        name: getText('datepicker_august'),
    },
    {
        id: 9,
        value: 9,
        name: getText('datepicker_september'),
    },
    {
        id: 10,
        value: 10,
        name: getText('datepicker_october'),
    },
    {
        id: 11,
        value: 11,
        name: getText('datepicker_november'),
    },
    {
        id: 12,
        value: 12,
        name: getText('datepicker_december'),
    },
];
export const INSURANCE_POLICY_STATUS = [
    {
        id: 'active',
        text: getText('insurance_policy_status_active'),
        value: 'active',
    },
    {
        id: 'canceled',
        text: getText('insurance_policy_status_cancelled'),
        value: 'canceled',
    },
];

// Warning: adding a section here will require to add a new section in many places of Selfcare
// TODO: refactor Selfcare to use a single source of truth for NETWORK_SECTIONS
// and remove the hard-coded string values (e.g. 'items', 'services')
export const NETWORK_SECTIONS = {
    ITEMS: 'items',
    SERVICES: 'services',
};

export const ITEMS_PER_PAGE = 15;
export const INITIAL_SELECTORS_STATE_DEFAULT_VALUE = {
    type: {
        list: ADMIN_FINANCIAL_ENTITIES_TYPE_LIST,
        selected: [],
    },
};

export const INITIAL_HEADERS_STATE_DEFAULT_VALUE = [
    {
        id: 0,
        name: 'Entity',
        field: 'name',
        isSortable: true,
        hasIcon: true,
        hasCheckbox: false,
    },
    {
        id: 1,
        name: 'Entity type',
        field: 'default_financialentitytype',
        isSortable: true,
        hasIcon: null,
    },
    {
        id: 2,
        name: 'Location',
        field: 'country_name',
        isSortable: true,
        hasIcon: null,
    },
];

export type CampaignVoucherColFields = 'code' | 'id' | 'name' | 'email' | 'status';

export const CAMPAIGN_VOUCHERS_TABLE_INITIAL_HEADERS: {
    id: number;
    name: string;
    field: CampaignVoucherColFields;
    isSortable: boolean;
    hasIcon: boolean;
}[] = [
        {
            id: 0,
            name: getText('campaign_voucher_code'),
            field: 'code',
            isSortable: false,
            hasIcon: true,
        },
        {
            id: 1,
            name: 'ID',
            field: 'id',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 2,
            name: getText('campaign_name'),
            field: 'name',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 3,
            name: getText('campaign_email'),
            field: 'email',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 4,
            name: getText('campaign_status'),
            field: 'status',
            isSortable: false,
            hasIcon: null,
        },
    ];

export const INITIAL_FILTERS_STATE_DEFAULT_VALUE = {
    field: null,
    direction: 'desc',
};

export const OLD_SELLERS_INVITES_TABLE_HEADER = {
    header: [
        {
            id: 1,
            name: getText('sellers_seller_products_table_header_product'), //'Product',
            field: 'product',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 2,
            name: getText('sellers_seller_products_table_header_distributor'), //'Distributor',
            field: 'distributor',
            isSortable: false,
            hasIcon: false,
        },
        {
            id: 3,
            name: getText('sellers_seller_products_table_header_store'), //'Store',
            field: 'store',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 4,
            name: getText('sellers_seller_products_table_header_created_on'), //'Created on',
            field: 'created',
            isSortable: false,
            hasIcon: null,
        },
    ],
    pagination: {
        current: '--',
        total: '--',
        next: false,
        page_start_index: 0,
    },
    filters: {
        field: null,
        direction: 'desc',
    },
    chunk: 15,
};

export const SELLER_INVITES_TABLE_HEADER = {
    header: [
        {
            id: 0,
            name: getText('sellers_seller_products_table_header_product'),
            field: 'product',
            isSortable: false,
            hasIcon: false,
        },
        {
            id: 1,
            name: getText('sellers_seller_products_table_header_distributor'),
            field: 'distributor',
            isSortable: false,
            hasIcon: false,
        },
        {
            id: 2,
            name: getText('sellers_seller_products_table_header_line_of_business'), // 'Ramo',
            field: 'line_of_business',
            isSortable: false,
            hasIcon: false,
        },
        {
            id: 3,
            name: getText('sellers_seller_products_table_header_product_outlets'),
            field: 'outlet',
            isSortable: false,
            hasIcon: false,
        },
    ],
    pagination: {
        current: '--',
        total: '--',
        next: false,
        page_start_index: 0,
    },
    filters: {
        field: null,
        direction: 'desc',
    },
    chunk: 15,
};

export const SELLERS_VENDORS_TABLE_HEADER = {
    header: [
        {
            id: 0,
            name: getText('utils_constants_namelabel_sellers_vendors_table_header'),
            field: 'name',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 1,
            name: getText('utils_constants_authorizedproductslabel_sellers_vendors_table_header'),
            field: 'authorized_products',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 2,
            name: getText('utils_constants_outletslabel_sellers_vendors_table_header'),
            field: 'outlets',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 3,
            name: getText('utils_constants_statuslabel_sellers_vendors_table_header'),
            field: 'status',
            isSortable: false,
            hasIcon: null,
        },
    ],
    pagination: {
        current: '--',
        total: '--',
        next: false,
        page_start_index: 0,
    },
    filters: {
        field: null,
        direction: 'desc',
    },
    chunk: 15,
};

export const DISTRIBUTORS_DISTRIBUTORS_TABLE_HEADER = {
    header: [
        {
            id: 0,
            name: getText('distributors_distributors_name_column'),
            field: 'name',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 1,
            name: getText('distributors_distributors_authorized_products_column'),
            field: 'authorized_products',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 2,
            name: getText('distributors_distributors_state_column'),
            field: 'status',
            isSortable: false,
            hasIcon: null,
        },
    ],
    pagination: {
        current: '--',
        total: '--',
        next: false,
        page_start_index: 0,
    },
    filters: {
        field: null,
        direction: 'desc',
    },
    chunk: 15,
};

export const DISTRIBUTOR_OUTLETS_TABLE_HEADER = {
    header: [
        {
            id: 0,
            name: getText('distributor_points_of_sale_table_header_name'),
            field: 'name',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 1,
            name: getText('distributor_points_of_sale_table_header_type'),
            field: 'type',
            isSortable: false,
            hasIcon: false,
        },
        {
            id: 2,
            name: getText('distributor_points_of_sale_table_header_location'),
            field: 'location',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 3,
            name: getText('distributor_points_of_sale_table_header_state'),
            field: 'status',
            isSortable: false,
            hasIcon: null,
        },
    ],
    pagination: {
        current: '--',
        total: '--',
        next: false,
        page_start_index: 0,
    },
    filters: {
        field: null,
        direction: 'desc',
    },
    chunk: 15,
};

export const CAMPAIGN_TABLE_HEADER = {
    header: [
        {
            id: 0,
            name: getText('campaign_header_title_campaign'),
            field: 'name',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 1,
            name: getText('campaign_header_title_number_of_vouchers'),
            field: 'count_generated_vouchers',
            isSortable: false,
            hasIcon: false,
        },
        {
            id: 2,
            name: getText('campaign_header_title_recovered_number'),
            field: 'not_returned_yet_from_be_field',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 4,
            name: getText('campaign_header_title_duration'),
            field: 'duration',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 5,
            name: getText('campaign_header_title_state'),
            field: 'state',
            isSortable: false,
            hasIcon: null,
        },
    ],
    pagination: {
        current: '--',
        total: '--',
        next: false,
        page_start_index: 0,
    },
    filters: {
        field: null,
        direction: 'desc',
    },
    chunk: 15,
};

export const LEADS_GENERATOR_TABLE_HEADER = {
    header: [
        {
            id: 0,
            name: getText('distributors_leads_generator_table_header_name_field'),
            field: 'name',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 1,
            name: getText('distributors_leads_generator_table_header_authorized_products'),
            field: 'authorized_products',
            isSortable: false,
            hasIcon: false,
        },
        {
            id: 2,
            name: getText('distributors_leads_generator_table_header_points_of_sale'),
            field: 'outlets',
            isSortable: false,
            hasIcon: null,
        },
        {
            id: 3,
            name: getText('distributors_leads_generator_table_header_state'),
            field: 'status',
            isSortable: false,
            hasIcon: null,
        },
    ],
    pagination: {
        current: '--',
        total: '--',
        next: false,
        page_start_index: 0,
    },
    filters: {
        field: null,
        direction: 'desc',
    },
    chunk: 15,
};

export const LEADS_GENERATOR_TABLE_POINTS_HEADER = {
    header: [
        {
            id: 0,
            name: getText('distributors_leads_generator_points_of_sale_table_header_name'),
            field: 'name',
            isSortable: false,
            hasIcon: false,
        },
        {
            id: 1,
            name: getText('distributors_leads_generator_points_of_sale_table_header_type'),
            field: 'type',
            isSortable: false,
            hasIcon: false,
        },
        {
            id: 2,
            name: getText('distributors_leads_generator_points_of_sale_table_header_location'),
            field: 'location',
            isSortable: false,
            hasIcon: false,
        },
        {
            id: 3,
            name: getText('distributors_leads_generator_points_of_sale_table_header_state'),
            field: 'status',
            isSortable: false,
            hasIcon: false,
        },
    ],
    pagination: {
        current: '--',
        total: '--',
        next: false,
        page_start_index: 0,
    },
    filters: {
        field: null,
        direction: 'desc',
    },
    chunk: 15,
};

export const STATUS_TS = [
    'payment_date',
    'payment_paid_ts',
    'payment_refunded_ts',
    'payment_error_ts',
    'payment_canceled_ts',
    'payment_pending_ts',
];

export const STATUS_TS_LABELS = {
    payment_date: getText('financial_flow_status_label_payment_date'),
    payment_paid_ts: getText('financial_flow_status_label_payment_paid_ts'),
    payment_refunded_ts: getText('financial_flow_status_label_payment_refunded_ts'),
    payment_error_ts: getText('financial_flow_status_label_payment_error_ts'),
    payment_canceled_ts: getText('financial_flow_status_label_payment_canceled_ts'),
};

export const PAYMENT_STATE_OPTIONS = [
    {
        id: 'paid',
        text: getText('payment_state_options_paid'),
        value: 'paid',
    },
    {
        id: 'refunded',
        text: getText('payment_state_options_refunded'),
        value: 'refunded',
    },
    {
        id: 'error',
        text: getText('payment_state_options_error'),
        value: 'error',
    },
    {
        id: 'canceled',
        text: getText('payment_state_options_cancelled'),
        value: 'canceled',
    },
    {
        id: 'pending',
        text: getText('payment_state_options_pending'),
        value: 'pending',
    },
];

export const LANGUAGE_DROPDOWN_OPTIONS = [
    {
        id: 'pt',
        text: 'PT',
        value: 'pt',
    },
    {
        id: 'en',
        text: 'EN',
        value: 'en',
    },
];

export const VOUCHER_EMISSION_AVAILABLE_COUNTRIES = [
    {
        id: 'PT',
        text: '🇵🇹 Portugal',
        value: 'PT',
    },
];

export const DISTRIBUTOR_SELLERS_INSURANCE_PRODUCTS = [
    {
        label: 'Seguro de Óculos',
        checked: true,
        theme: 'round',
        variant: 'light',
    },

    {
        label: 'Seguro de Auto',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
];

export const DISTRIBUTOR_SELLERS_INSURANCE_PRODUCTS2 = [
    {
        id: 0,
        namespace: 'Seguro de Óculos',
        checked: true,
        theme: 'round',
        variant: 'light',
    },

    {
        id: 1,
        namespace: 'Seguro de Auto',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 2,
        namespace: 'Seguro de Carro',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 3,
        namespace: 'Seguro de Moto',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 4,
        namespace: 'Seguro de Celular',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 5,
        namespace: 'Seguro de Televisão',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 6,
        namespace: 'Seguro de Geladeira',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 7,
        namespace: 'Seguro de Ônibus',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 8,
        namespace: 'Seguro de Avião',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 9,
        namespace: 'Seguro de Helicóptero',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 10,
        namespace: 'Seguro de Jato',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 11,
        namespace: 'Seguro de Foguete',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 12,
        namespace: 'Seguro de Carro',
        checked: true,
        theme: 'round',
        variant: 'light',
    },

    {
        id: 13,
        namespace: 'Seguro de Auto',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 14,
        namespace: 'Seguro de Carro',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 15,
        namespace: 'Seguro de Moto',
        checked: true,
        theme: 'round',
        variant: 'light',
    },

    {
        id: 16,
        namespace: 'Seguro de Auto',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 17,
        namespace: 'Seguro de Carro',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 18,
        namespace: 'Seguro de Moto',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 19,
        namespace: 'Seguro de Moto',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 20,
        namespace: 'Seguro de Bicicleta',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 21,
        namespace: 'Seguro de Skate',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 22,
        namespace: 'Seguro de Patins',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 23,
        namespace: 'Seguro de Patinete',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
    {
        id: 24,
        namespace: 'Seguro de Mobilete',
        checked: true,
        theme: 'round',
        variant: 'light',
    },
];

export const DISTRIBUTOR_SELLERS_OUTLETS = [
    {
        id: 'ponto de venda 1 ( Online )',
        text: 'Ponto de venda 1 ( Online )',
        value: 'ponto de venda 1 ( Online )',
        order_index: 0,
    },
];

export const DISTRIBUTOR_STATUS_FILTERS = [
    {
        id: 'active',
        text: getText('distributor_status_filters_active'),
        value: 'active',
        order_index: 0,
    },
    {
        id: 'inactive',
        text: getText('distributors_status_filters_inactive'),
        value: 'inactive',
        order_index: 1,
    },
];

export const ADMIN_DISTRIBUTOR_STATUS_CHANGEABLE_FILTERS = [
    {
        id: 'active',
        text: getText('admin_distributor_status_changeable_filters_active'),
        value: 'active',
        order_index: 0,
    },
    {
        id: 'approved',
        text: getText('admin_distributor_status_changeable_filters_approved'),
        value: 'approved',
        order_index: 1,
    },
    {
        id: 'inactive',
        text: getText('admin_distributor_status_changeable_filters_inactive'),
        value: 'inactive',
        order_index: 2,
    },
    {
        id: 'pending',
        text: getText('admin_distributor_status_changeable_filters_pending'),
        value: 'pending',
        order_index: 3,
    },
    {
        id: 'rejected',
        text: getText('admin_distributor_status_changeable_filters_rejected'),
        value: 'rejected',
        order_index: 4,
    },
];

export const ADMIN_DISTRIBUTOR_STATUS_FILTERS = [
    {
        id: 'active',
        text: getText('admin_distributor_status_filters_active'),
        value: 'active',
        order_index: 0,
    },
    {
        id: 'approved',
        text: getText('admin_distributor_status_filters_approved'),
        value: 'approved',
        order_index: 1,
    },
    {
        id: 'pending',
        text: getText('admin_distributor_status_filters_pending'),
        value: 'pending',
        order_index: 2,
    },
    {
        id: 'processing',
        text: getText('admin_distributor_status_filters_processing'),
        value: 'processing',
        order_index: 3,
    },
    {
        id: 'inactive',
        text: getText('admin_distributor_status_filters_inactive'),
        value: 'inactive',
        order_index: 4,
    },
    {
        id: 'rejected',
        text: getText('admin_distributor_status_filters_rejected'),
        value: 'rejected',
        order_index: 5,
    },
];

export const ADMIN_PROVIDER_STATUS_FILTERS = [
    {
        id: 'active',
        text: getText('admin_provider_status_filters_active'),
        value: 'active',
        order_index: 0,
    },
    {
        id: 'approved',
        text: getText('admin_provider_status_filters_approved'),
        value: 'approved',
        order_index: 1,
    },
    {
        id: 'pending',
        text: getText('admin_provider_status_filters_pending'),
        value: 'pending',
        order_index: 2,
    },
    {
        id: 'processing',
        text: getText('admin_provider_status_filters_processing'),
        value: 'processing',
        order_index: 3,
    },
    {
        id: 'inactive',
        text: getText('admin_provider_status_filters_inactive'),
        value: 'inactive',
        order_index: 4,
    },
    {
        id: 'rejected',
        text: getText('admin_provider_status_filters_rejected'),
        value: 'rejected',
        order_index: 5,
    },
];

export const POSTAL_CODE_MASKS_BY_COUNTRY = {
    masks: {
        BR: {
            mask: '99999-999',
            maskPlaceholder: '00000-000',
        },
        PT: {
            mask: '9999-999',
            maskPlaceholder: '0000-000',
        },
        ES: {
            mask: '',
            maskPlaceholder: '',
        },
    },
};

export const REGISTER_LOGIN_FLOW_POSTAL_CODE_MASKS = {
    masks: {
        BR: {
            mask: '99999-999',
            maskPlaceholder: '00000-000',
        },
        PT: {
            mask: '9999-999',
            maskPlaceholder: '0000-000',
        },
    },
};

export const REGISTER_LOGIN_FLOW_DOCUMENT_MASKS = {
    masks: {
        BR: {
            cpf: {
                mask: '99999999999',
                maskPlaceholder: '00000000000',
            },
            cnpj: {
                mask: '99999999999999',
                maskPlaceholder: '00000000000000',
            },
        },
        PT: {
            mask: '999999999',
            maskPlaceholder: '000000000',
        },
    },
};

export const LIFE_AND_NON_LIFE = [
    {
        id: 'io.habit.insurance.product_type.life',
        text: 'Life',
        value: 'io.habit.insurance.product_type.life',
    },
    {
        id: 'io.habit.insurance.product_type.nonlife',
        text: 'Non Life',
        value: 'io.habit.insurance.product_type.nonlife',
    },
    {
        id: 'io.habit.insurance.product_type.life_and_nonlife',
        text: 'Life and Non Life',
        value: ['io.habit.insurance.product_type.nonlife', 'io.habit.insurance.product_type.life'],
    },
];

export const DISTRIBUTORS_AVAILABLE_COUNTRIES = {
    portugal: {
        id: 'portugal',
        text: getText('portugal'),
        value: 'portugal',
        acronym: 'PT',
        order_index: 0,
    },
    brasil: {
        id: getCurrentLanguage() === 'pt' ? 'brasil' : 'brazil',
        text: getText('brazil'),
        value: getCurrentLanguage() === 'pt' ? 'brasil' : 'brazil',
        acronym: 'BR',
        order_index: 1,
    },
    espanha: {
        id: 'espanha',
        text: getText('spain'),
        value: 'espanha',
        acronym: 'ES',
        order_index: 2,
    },
};

export const DEFAULT_DISTRIBUTOR_SELECTED_FILTER_NAMESPACE = [DISTRIBUTOR_STATUS_FILTERS[0].id];

export const ALL_ENTITIES_SIZE = 999;

export const OVERWRITTEN_VALUES_REGISTRATION = [
    [
        {
            namespace: 'authorized_branch',
            data: '',
        },
        {
            namespace: 'asf_number',
            data: '',
        },
    ],
    [
        {
            namespace: 'permanent_certificate_number',
            data: '',
        },
    ],
];
export const NETWORKS_TABLE_HEADER = [
    {
        id: 0,
        name: getText('selfcare_network_section_service_label'),
        field: 'name',
        isSortable: false,
        hasIcon: false,
    },
    {
        id: 1,
        name: getText('selfcare_network_section_retail_price_label'),
        field: 'list_price_amount',
        isSortable: false,
        hasIcon: null,
    },
    {
        id: 2,
        name: getText('selfcare_network_section_contracted_price_label'),
        field: 'agreed_amount',
        isSortable: false,
        hasIcon: null,
    },
    {
        id: 3,
        name: getText('selfcare_network_section_copayment_label'),
        field: 'copayment_amount',
        isSortable: false,
        hasIcon: null,
    },
    {
        id: 4,
        name: getText('selfcare_network_section_coverages_label'),
        field: 'coberturas',
        isSortable: false,
        hasIcon: null,
    },
];

export const FIELD_STATUS = {
    pending: 'start_date',
    canceled: 'canceled_ts',
    paid: 'paid_ts',
    authorized: 'start_date',
    error: 'error_ts',
    refunded: 'refunded_ts',
    emission: 'emission_ts',
    disputed: 'start_date',
};

export const CAMPAIGN_LANGUAGES = [
    {
        id: 'en',
        text: getText('english_language'),
        value: 'en',
    },
    {
        id: 'es',
        text: getText('spanish_language'),
        value: 'es',
    },
    {
        id: 'fr',
        text: getText('french_language'),
        value: 'fr',
    },
];
export const PT_ONLY_CAMPAIGN_LANGUAGE = [
    {
        id: 'pt',
        text: getText('portuguese_language'),
        value: 'pt',
    },
];
export type GenericDropdownOption = {
    id: string;
    value: string;
    text: string;
};
export type DistributionConfigurationType = 'need_configuration' | 'exists_default';
export type DistributionConfigurationDropdownOption = {
    id: DistributionConfigurationType;
    value: DistributionConfigurationType;
    text: string;
};
export const DISTRIBUTION_CONFIGURATIONS: DistributionConfigurationDropdownOption[] = [
    {
        id: 'need_configuration',
        text: 'Need Configuration',
        value: 'need_configuration' as DistributionConfigurationType,
    },
    {
        id: 'exists_default',
        text: 'Exists Default',
        value: 'exists_default' as DistributionConfigurationType,
    },
];

export type DistributionAuthorizedFlows = 'guest' | 'seller' | 'lead' | 'voucher';
export type DistributionAuthorizedFlowsTypes = 'guest' | 'seller' | 'lead' | 'voucher';
export type DistributionAuthorizedFlowsDropdownOption = {
    id: DistributionAuthorizedFlowsTypes;
    value: DistributionAuthorizedFlowsTypes | [];
    text: string;
};
export const DISTRIBUTION_AUTHORIZED_FLOWS: DistributionAuthorizedFlowsDropdownOption[] = [
    {
        id: 'voucher',
        text: 'Voucher',
        value: 'voucher' as DistributionAuthorizedFlows,
    },
    {
        id: 'guest',
        text: getText('distribution_authorized_flows_guest_label'),
        value: 'guest' as DistributionAuthorizedFlows,
    },
    {
        id: 'seller',
        text: getText('distribution_authorized_flows_seller_label'),
        value: 'seller' as DistributionAuthorizedFlows,
    },
    {
        id: 'lead',
        text: getText('distribution_authorized_flows_lead_label'),
        value: 'lead' as DistributionAuthorizedFlows,
    },
];

export type PortalTypes = 'guest' | 'seller' | 'lead';
export type PortalTypesDropdownOption = {
    id: PortalTypes;
    value: PortalTypes | [];
    text: string;
};
export const PORTAL_TYPES: PortalTypesDropdownOption[] = [
    {
        id: 'guest',
        text: getText('distribution_authorized_flows_guest_label'),
        value: 'guest' as PortalTypes,
    },
    {
        id: 'seller',
        text: getText('distribution_authorized_flows_seller_label'),
        value: 'seller' as PortalTypes,
    },
    {
        id: 'lead',
        text: getText('distribution_authorized_flows_lead_label'),
        value: 'lead' as PortalTypes,
    },
];
export const SELFCARE_AVAILABLE_LANGUAGES: Array<string> = ['pt', 'en', 'es'];

export const FAKE_TABLE_MOCK = [
    {
        id: '1',
        code: '123',
        name: 'Voucher 1',
        email: 'example1@gmail.com',
        status: 'active',
    },
    {
        id: '2',
        code: '124',
        name: 'Voucher 2',
        email: 'example2@gmail.com',
        status: 'inactive',
    },
    {
        id: '3',
        code: '125',
        name: 'Voucher 3',
        email: 'example3@gmail.com',
        status: 'active',
    },
    {
        id: '4',
        code: '126',
        name: 'Voucher 4',
        email: 'example4@gmail.com',
        status: 'active',
    },
    {
        id: '5',
        code: '127',
        name: 'Voucher 5',
        email: 'example5@gmail.com',
        status: 'inactive',
    },
    {
        id: '6',
        code: '128',
        name: 'Voucher 6',
        email: 'example6@gmail.com',
        status: 'active',
    },
    {
        id: '7',
        code: '129',
        name: 'Voucher 7',
        email: 'example7@gmail.com',
        status: 'inactive',
    },
    {
        id: '8',
        code: '130',
        name: 'Voucher 8',
        email: 'example8@gmail.com',
        status: 'active',
    },
    {
        id: '9',
        code: '131',
        name: 'Voucher 9',
        email: 'example9@gmail.com',
        status: 'inactive',
    },
    {
        id: '10',
        code: '132',
        name: 'Voucher 10',
        email: 'example10@gmail.com',
        status: 'active',
    },
    {
        id: '11',
        code: '133',
        name: 'Voucher 11',
        email: 'example11@gmail.com',
        status: 'active',
    },
    {
        id: '12',
        code: '134',
        name: 'Voucher 12',
        email: 'example12@gmail.com',
        status: 'inactive',
    },
    {
        id: '13',
        code: '135',
        name: 'Voucher 13',
        email: 'example13@gmail.com',
        status: 'active',
    },
    {
        id: '14',
        code: '136',
        name: 'Voucher 14',
        email: 'example14@gmail.com',
        status: 'inactive',
    },
    {
        id: '15',
        code: '137',
        name: 'Voucher 15',
        email: 'example15@gmail.com',
        status: 'active',
    },
    {
        id: '16',
        code: '138',
        name: 'Voucher 16',
        email: 'example16@gmail.com',
        status: 'active',
    },
    {
        id: '17',
        code: '139',
        name: 'Voucher 17',
        email: 'example17@gmail.com',
        status: 'inactive',
    },
    {
        id: '18',
        code: '140',
        name: 'Voucher 18',
        email: 'example18@gmail.com',
        status: 'active',
    },
    {
        id: '19',
        code: '141',
        name: 'Voucher 19',
        email: 'example19@gmail.com',
        status: 'inactive',
    },
    {
        id: '20',
        code: '142',
        name: 'Voucher 20',
        email: 'example20@gmail.com',
        status: 'active',
    },
    {
        id: '21',
        code: '143',
        name: 'Voucher 21',
        email: 'example21@gmail.com',
        status: 'active',
    },
    {
        id: '22',
        code: '144',
        name: 'Voucher 22',
        email: 'example22@gmail.com',
        status: 'inactive',
    },
    {
        id: '23',
        code: '145',
        name: 'Voucher 23',
        email: 'example23@gmail.com',
        status: 'active',
    },
    {
        id: '24',
        code: '146',
        name: 'Voucher 24',
        email: 'example24@gmail.com',
        status: 'inactive',
    },
    {
        id: '25',
        code: '147',
        name: 'Voucher 25',
        email: 'example25@gmail.com',
        status: 'active',
    },
    {
        id: '26',
        code: '148',
        name: 'Voucher 26',
        email: 'example26@gmail.com',
        status: 'active',
    },
    {
        id: '27',
        code: '149',
        name: 'Voucher 27',
        email: 'example27@gmail.com',
        status: 'inactive',
    },
    {
        id: '28',
        code: '150',
        name: 'Voucher 28',
        email: 'example28@gmail.com',
        status: 'active',
    },
    {
        id: '29',
        code: '151',
        name: 'Voucher 29',
        email: 'example29@gmail.com',
        status: 'inactive',
    },
    {
        id: '30',
        code: '152',
        name: 'Voucher 30',
        email: 'example30@gmail.com',
        status: 'active',
    },
    [
        {
            id: '31',
            code: '153',
            name: 'Voucher 31',
            email: 'example31@gmail.com',
            status: 'active',
        },
        {
            id: '32',
            code: '154',
            name: 'Voucher 32',
            email: 'example32@gmail.com',
            status: 'inactive',
        },
        {
            id: '33',
            code: '155',
            name: 'Voucher 33',
            email: 'example33@gmail.com',
            status: 'active',
        },
        {
            id: '34',
            code: '156',
            name: 'Voucher 34',
            email: 'example34@gmail.com',
            status: 'inactive',
        },
        {
            id: '35',
            code: '157',
            name: 'Voucher 35',
            email: 'example35@gmail.com',
            status: 'active',
        },
        {
            id: '36',
            code: '158',
            name: 'Voucher 36',
            email: 'example36@gmail.com',
            status: 'inactive',
        },
        {
            id: '37',
            code: '159',
            name: 'Voucher 37',
            email: 'example37@gmail.com',
            status: 'active',
        },
        {
            id: '38',
            code: '160',
            name: 'Voucher 38',
            email: 'example38@gmail.com',
            status: 'inactive',
        },
        {
            id: '39',
            code: '161',
            name: 'Voucher 39',
            email: 'example39@gmail.com',
            status: 'active',
        },
        {
            id: '40',
            code: '162',
            name: 'Voucher 40',
            email: 'example40@gmail.com',
            status: 'inactive',
        },
        {
            id: '41',
            code: '163',
            name: 'Voucher 41',
            email: 'example41@gmail.com',
            status: 'active',
        },
        {
            id: '42',
            code: '164',
            name: 'Voucher 42',
            email: 'example42@gmail.com',
            status: 'inactive',
        },
        {
            id: '43',
            code: '165',
            name: 'Voucher 43',
            email: 'example43@gmail.com',
            status: 'active',
        },
        {
            id: '44',
            code: '166',
            name: 'Voucher 44',
            email: 'example44@gmail.com',
            status: 'inactive',
        },
        {
            id: '45',
            code: '167',
            name: 'Voucher 45',
            email: 'example45@gmail.com',
            status: 'active',
        },
        {
            id: '46',
            code: '168',
            name: 'Voucher 46',
            email: 'example46@gmail.com',
            status: 'inactive',
        },
        {
            id: '47',
            code: '169',
            name: 'Voucher 47',
            email: 'example47@gmail.com',
            status: 'active',
        },
        {
            id: '48',
            code: '170',
            name: 'Voucher 48',
            email: 'example48@gmail.com',
            status: 'inactive',
        },
        {
            id: '49',
            code: '171',
            name: 'Voucher 49',
            email: 'example49@gmail.com',
            status: 'active',
        },
        {
            id: '50',
            code: '172',
            name: 'Voucher 50',
            email: 'example50@gmail.com',
            status: 'inactive',
        },
    ],
];

export const CLAIMS_CLASSES_MAP = {
    eyehearingwear: 'io.habit.claims.eyehearingwear',
};

export const INITIAL_EMIT_VOUCHERS_STEPS: Steps = [
    {
        icon: 'none',
        number: 0,
        label: getText('emit_voucher_data'),
        isDisabled: false,
        current: true,
        isDouble: false,
        isSubmit: false,
        isBackOnly: false,
        isSubmitOnly: false,
    },
    {
        icon: 'none',
        number: 1,
        label: getText('billing_data_title'),
        isDisabled: false,
        current: false,
        isDouble: true,
        isSubmit: true,
        isBackOnly: false,
        isSubmitOnly: false,
    },
    {
        icon: 'none',
        number: 2,
        label: getText('emit_voucher_payment_method'),
        isDisabled: false,
        current: false,
        isDouble: false,
        isSubmit: false,
        isBackOnly: true,
        isSubmitOnly: false,
    },
    {
        icon: 'none',
        number: 3,
        label: getText('emit_voucher_payment_method'),
        isDisabled: false,
        current: false,
        isDouble: false,
        isSubmit: false,
        isBackOnly: false,
        isSubmitOnly: true,
    },
];

export const INITIAL_CAMPAIGN: Emission = {
    address: '',
    campaign_id: '',
    city: '',
    country: '',
    entity_name: '',
    group_name: '',
    mobile: '',
    postal_code: '',
    purchase_responsible_name: '',
    pretended_voucher_amount: 0,
    voucher_code: '',
    flag_agreement_accepted: false,
    privacy_policy: false,
    email: '',
    tax_country: '',
    tax_id: '',
};
