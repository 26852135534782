import classnames from 'classnames';
import React, { useRef } from 'react';
import Loading from 'shared/Loading';
import ValidationStatus from 'shared/ValidationStatus';
import Styles from './UploadAsset.module.scss';

type UploadAssetProps = {
    name?: string;
    types?: string;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    file?: string;
    namespace?: string;
    withValidation?: boolean;
    isValid?: boolean;
    unsaved?: boolean;
    onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
};

const UploadAsset: React.FC<UploadAssetProps> = ({
    name,
    types,
    disabled,
    onChange,
    file,
    namespace,
    withValidation,
    isValid,
    unsaved,
    onDelete,
    isLoading,
}) => {
    const myRef = useRef(null);

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        myRef.current.click();
    };

    const handleOnDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        myRef.current.value = '';
        onDelete(e);
    };

    const icon = isLoading ? <Loading /> : <i className="icon-icon_attachment"></i>;

    return (
        <div data-testid="UploadDocumentContainer" className={Styles.upload}>
            <input
                data-testid="UploadDocumentInput"
                data-namespace={namespace}
                ref={myRef}
                type="file"
                name={name}
                onChange={onChange}
                accept={types}
                disabled={disabled}
                aria-disabled={disabled}
            />

            {!file && (
                <button
                    data-testid="UploadDocumentWithoutFile"
                    data-namespace={namespace}
                    className={Styles.withoutFile}
                    disabled={disabled}
                    aria-disabled={disabled}
                    onClick={handleOnClick}
                    type="button"
                    title="Upload Document"
                >
                    {icon}
                </button>
            )}

            {file && (
                <>
                    <button
                        data-testid="UploadDocumentWithFile"
                        data-namespace={namespace}
                        data-tip={file}
                        className={classnames(Styles.withFile, disabled ? Styles.disabled : null)}
                        title={file}
                        onClick={handleOnClick}
                        type="button"
                    >
                        {icon}
                        <span>
                            {onDelete ? (
                                <i
                                    data-testid="UploadDocumentDelete"
                                    data-namespace={namespace}
                                    data-tip="Clear"
                                    className={classnames(
                                        'icon-icon_delete_2',
                                        disabled ? Styles.disabled : null,
                                    )}
                                    onClick={handleOnDelete}
                                />
                            ) : null}
                        </span>
                    </button>
                </>
            )}

            {withValidation && !unsaved && !disabled && isValid && (
                <ValidationStatus status="success" />
            )}

            {withValidation && !unsaved && !disabled && !isValid && (
                <ValidationStatus status="error" />
            )}
        </div>
    );
};

export default UploadAsset;
