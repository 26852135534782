import { Observable } from 'rxjs';
import { apiGetAuthHeader, apiHandleRequest } from 'services/api';
import {
    DEFAULT_PAGE_SIZE,
    INSURANCE_MEDIA_PARAM_PROPERTY_CLASS,
    OBD_VALUES_BY_ENV,
} from 'utils/constants';
import { getEnv, isDevEnv, isStgEnv } from 'utils/functions';
import routes from './routes';

export const getInsureeData = async params => {
    const url = routes.insuranceInsuree.replace(':id', params.id);

    var request;
    if (params.insuree_id) {
        request = {
            method: 'post',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
            data: {
                insuree_id: params.insuree_id ? params.insuree_id : null,
            },
        };
    } else if (params.quote_id) {
        request = {
            method: 'post',
            url: url,
            headers: {
                Authorization: apiGetAuthHeader(),
                'Content-Type': 'application/json',
            },
            data: {
                quote_id: params.quote_id ? params.quote_id : null,
            },
        };
    }

    return await apiHandleRequest(request);
};

export const getInsuranceMetaData = async params => {
    const url = routes.metaData;

    const payload = {
        namespace: params.namespace ? params.namespace : '',
        type: params.type ? params.type : 'count',
        params: params.params ? params.params : null,
    };

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: payload,
    };

    return await apiHandleRequest(request);
};

export const searchInsuranceByType = async params => {
    let url = '';
    switch (params.type) {
        case 'insurees':
            url = routes.insuranceInsureesSearch.replace(':id', params.id);
            break;
        case 'quotes':
            url = routes.insuranceQuotesSearch.replace(':id', params.id);
            break;
        case 'assets':
            url = routes.insuranceAssetsSearch.replace(':id', params.id);
            break;
        case 'policies':
            url = routes.insurancePoliciesSearch.replace(':id', params.id);
            break;
        case 'claims':
            url = routes.insuranceClaimsSearch.replace(':id', params.id);
            break;
        case 'payments':
        case 'billing':
            url = routes.insurancePaymentsDocumentsSearch.replace(':id', params.id);
            break;
        default:
            break;
    }
    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const insureeFinder = async params => {
    const url = routes.insuranceContextFinder.replace(':id', params.app_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const getInsuranceCarSpecs = async () => {
    let baseURL = 'https://cdn.habit.io';
    if (isDevEnv() || isStgEnv()) {
        baseURL = 'https://cdn.integrations.habit.io';
    }

    const request = {
        method: 'get',
        baseURL: baseURL,
        url: routes.insuranceCarSpecs,
    };

    return await apiHandleRequest(request);
};

export const editQuoteAsset = async params => {
    const url = routes.insuranceEditQuoteAsset
        .replace(':id', params.app_id)
        .replace(':quote-id', params.quote_id)
        .replace(':protected-asset-id', params.asset_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const getCountryByNamespace = async namespace => {
    const url = routes.countryByNamespace.replace(':namespace', namespace);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getInsurancePayments = async params => {
    const url = routes.insuranceSubscriptionPayments
        .replace(':id', params.id)
        .replace(':subscription-id', params.subscriptionId);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            // page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
            // page_start_index: params && params.page_start_index ? params.page_start_index : 0,
            order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const getInsuranceServiceCostMonth = async url => {
    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getInsuranceDistanceMonth = async url => {
    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const updateClaimStatus = async params => {
    const url = routes.insurnanceUpdateClaimStatus
        .replace(':id', params.app_id)
        .replace(':claim-id', params.claim_id)
        .replace(':claim-mark-as', `claim-mark-as-${params.status}`);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const updateClaimProperties = async params => {
    const url = routes.insurnanceUpdateClaimProperties
        .replace(':id', params.app_id)
        .replace(':claim-id', params.claim_id)
        .replace(':property-id', params.property_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: { data: params.data },
        // data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const createClaim = async params => {
    const url = routes.insuranceCreateClaim
        .replace(':id', params.app_id)
        .replace(':policy-id', params.policy_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const getAllClaimPossibleStatuses = async params => {
    const url = routes.insuranceGetAllClaimPossibleStatuses.replace(':id', params.app_id);
    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const getAllPolicyPossibleStatuses = async params => {
    const url = routes.insuranceGetAllPolicyPossibleStatuses.replace(':id', params.app_id);
    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

export const markPolicyAsCanceled = async params => {
    const url = routes.insuranceMarkPolicyAsCanceled
        .replace(':id', params.app_id)
        .replace(':policy-id', params.policy_id);

    let data = {
        reason: params.reason,
    };

    if (params.custom_reason) {
        Object.assign(data, {
            custom_reason: params.custom_reason,
        });
    }

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data,
    };

    return await apiHandleRequest(request);
};

export const markPolicyAsState = async params => {
    var state = params.state;
    if (state.includes(':')) {
        state = state.split(':')[1];
    }
    const url = routes.insuranceMarkPolicyAsState
        .replace(':id', params.app_id)
        .replace(':policy-id', params.policy_id)
        .replace(':state', state);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const getClaimDataById = async params => {
    const url = routes.insuranceGetClaimData
        .replace(':id', params.app_id)
        .replace(':claim-id', params.claim_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            // page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
            // page_start_index: params && params.page_start_index ? params.page_start_index : 0,
            // order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const getClaimPropertiesById = async params => {
    const url = routes.insuranceGetClaimProperties
        .replace(':id', params.app_id)
        .replace(':claim-id', params.claim_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            // page_size: params && params.page_size ? params.page_size : DEFAULT_PAGE_SIZE,
            // page_start_index: params && params.page_start_index ? params.page_start_index : 0,
            // order_by: params && params.order_by ? params.order_by : '-created',
        },
    };

    return await apiHandleRequest(request);
};

export const getClaimChatHistorySWR = async (key, app_id, claim_id) => {
    const url = routes.insuranceGetClaimChatHistory
        .replace(':id', app_id)
        .replace(':claim-id', claim_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            page_size: DEFAULT_PAGE_SIZE,
            order_by: '+created',
            classes:
                'elemMatch/{"$in": ["io.habit.claim.chat.message", "io.habit.claim.asset"]}/class_id',
        },
    };

    return await apiHandleRequest(request);
};

export const getClaimChatHistory = async params => {
    const url = routes.insuranceGetClaimChatHistory
        .replace(':id', params.app_id)
        .replace(':claim-id', params.claim_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params.params,
        },
    };

    return await apiHandleRequest(request);
};

export const claimChatHistoryCreateMessage = async params => {
    const url = routes.insuranceClaimChatHistoryCreateMessage
        .replace(':id', params.app_id)
        .replace(':claim-id', params.claim_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const claimChatHistoryCreateAsset = async params => {
    const url = routes.insuranceClaimChatHistoryCreateAsset
        .replace(':id', params.app_id)
        .replace(':claim-id', params.claim_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const downloadClaimChatHistoryAsset = async params => {
    const url = routes.insuranceClaimChatHistoryDownloadAsset
        .replace(':id', params.app_id)
        .replace(':claim-id', params.claim_id)
        .replace(':asset-id', params.asset_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params.params,
        },
    };

    return await apiHandleRequest(request);
};

export const assetEditOdometer = async params => {
    const url = routes.insuranceAssetEditOdometer
        .replace(':id', params.app_id)
        .replace(':quote-id', params.quote_id)
        .replace(':protected-asset-id', params.asset_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const assetEditOdometerReport = async params => {
    const env = getEnv();
    const url = routes.insuranceAssetEditOdometerReport.replace(
        '.:env.',
        env === 'production' ? `.` : `.${env}.`,
    );

    const request = {
        method: 'post',
        url: url,
        data: params,
    };

    return await apiHandleRequest(request);
};

export const assetRejectOdometerImage = async params => {
    const url = routes.insuranceAssetRejectOdometerImage
        .replace(':id', params.app_id)
        .replace(':quote-id', params.quote_id)
        .replace(':protected-asset-id', params.asset_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        // data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const assetOdometerHistory = async params => {
    const url = routes.insuranceAssetOdometerHistory
        .replace(':id', params.app_id)
        .replace(':quote-id', params.quote_id)
        .replace(':protected-asset-id', params.asset_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            order_by: params.params && params.params.order_by ? params.params.order_by : '-created',
            page_size:
                params.params && params.params.page_size
                    ? params.params.page_size
                    : DEFAULT_PAGE_SIZE,
        },
    };

    return await apiHandleRequest(request);
};

export const assetOdometerHistoryTotalDriven = async params => {
    const env = getEnv();

    const url = routes.insuranceOdometerHistoryTotalDriven
        .replace(':id', params.app_id)
        .replace(':channel-id', params.channel_id)
        .replace(':component-namespace', OBD_VALUES_BY_ENV[env].component_namespace)
        .replace(':property-namespace', OBD_VALUES_BY_ENV[env].property_total_distance_driven);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        // params: {
        //     ...params.params,
        // },
    };

    return await apiHandleRequest(request);
};

export const insuranceGetBillingData = async params => {
    const url = routes.insuranceGetPayments
        .replace(':id', params.app_id)
        .replace(':subscription-id', params.clientservice_id);

    const request = {
        method: 'get',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        params: {
            ...params.params,
        },
    };

    return await apiHandleRequest(request);
};

export const insuranceUpdatePayment = async params => {
    const url = routes.insuranceUpdatePayment
        .replace(':id', params.app_id)
        .replace(':payment-id', params.payment_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const insuranceUploadPaymentDocument = async params => {
    const url = routes.insuranceUploadPaymentDocument
        .replace(':id', params.app_id)
        .replace(':payment-id', params.payment_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const insuranceDeletePaymentDocument = async params => {
    const url = routes.insuranceDeletePaymentDocument
        .replace(':id', params.app_id)
        .replace(':subscription-id', params.subscription_id)
        .replace(':asset-id', params.asset_id);

    const request = {
        method: 'delete',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const insuranceAssetEditEligible = async params => {
    const url = routes.insuranceAssetEditEligible
        .replace(':id', params.app_id)
        .replace(':quote-id', params.quote_id)
        .replace(':protected-asset-id', params.asset_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data,
    };

    return await apiHandleRequest(request);
};

/**
 * ****** SWRs ******
 */

export const insureeFinderSWR = async (key, app_id, collection, id) => {
    const url = routes.insuranceContextFinder.replace(':id', app_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            collection: collection,
            id: id,
        },
    };

    return await apiHandleRequest(request);
};

export const getInsureeDataSWR = async (key, app_id, insuree_id) => {
    const url = routes.insuranceInsuree.replace(':id', app_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            insuree_id: insuree_id,
        },
    };

    return await apiHandleRequest(request);
};

/**
 * ****** OBSERVABLES ******
 */

export const assetOdometerHistoryTotalDrivenObs = params => {
    const obs = new Observable(observer => {
        assetOdometerHistoryTotalDriven(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const assetOdometerHistoryObs = params => {
    const obs = new Observable(observer => {
        assetOdometerHistory(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getInsuranceMetaDataObs = params => {
    const obs = new Observable(observer => {
        getInsuranceMetaData(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const insureeFinderObs = params => {
    const obs = new Observable(observer => {
        insureeFinder(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const searchInsuranceByTypeObs = params => {
    const obs = new Observable(observer => {
        searchInsuranceByType(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getInsurancePaymentsObs = params => {
    const obs = new Observable(observer => {
        getInsurancePayments(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getInsuranceServiceCostMonthObs = params => {
    const obs = new Observable(observer => {
        getInsuranceServiceCostMonth(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const getInsuranceDistanceMonthObs = params => {
    const obs = new Observable(observer => {
        getInsuranceDistanceMonth(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

export const updateClaimPropertiesObs = params => {
    const obs = new Observable(observer => {
        updateClaimProperties(params)
            .then(json => {
                observer.next(json);
            })
            .then(() => {
                observer.complete();
            })
            .catch(error => {
                const response = error && error.response;
                observer.error(response);
            });
    });

    return obs;
};

/**
 * @param {Object} params
 * @param {string} params.insuree_id
 * @param {Array} params.data
 * @param {string} params.product_id
 * @returns {Promise}
 *
 */
export const updateInsureeData = async params => {
    const url = routes.updateInsureeData
        .replace(':insuree-id', params.insuree_id)
        .replace(':product-id', params.product_id);
    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            properties: params.data,
        },
    };

    return await apiHandleRequest(request);
};

/**
 * @param {Object} params
 * @param {string} params.quote_id
 * @param {string} params.protected_asset_id
 * @param {string} params.application_id
 * @param {Array} params.data
 * @returns {Promise}
 *
 */
export const updateProtectedAssetData = async params => {
    const url = routes.updateProtectedAssetData
        .replace(':quote_id', params.quote_id)
        .replace('[:protected-asset-id]', params.protected_asset_id)
        .replace(':application_id', params.application_id);
    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            properties: params.data,
        },
    };

    return await apiHandleRequest(request);
};

/**
 * @param {Object} params
 * @param {string} params.application_id
 * @param {string} params.claim_id
 * @param {string} params.property_id
 * @param {string} params.data
 * @returns {Promise}
 */
export const updateClaimData = async params => {
    const url = routes.updateClaimData
        .replace(':application_id', params.application_id)
        .replace(':claim_id', params.claim_id)
        .replace(':property_id', params.property_id);
    const request = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: {
            data: params.data,
        },
    };
    return await apiHandleRequest(request);
};

/**
 * Creates a claim asset based on the file type.
 * @param {Object} params - The parameters for creating the claim asset.
 * @param {string} params.filename - The filename including extension.
 * @param {string} params.url - The URL of the asset.
 * @param {string} params.claim_id - The claim ID.
 * @param {string} params.project_id - The project ID.
 * @returns {Promise} A promise that resolves with the result of the API request.
 */
export const createClaimAsset = async params => {
    // Define constants
    const url = routes.createClaimAsset
        .replace(':project-id', params.project_id)
        .replace(':claim-id', params.claim_id);
    const fileExtension = params.filename.split('.').pop();

    // Determine file type
    const isFileAPhoto = /(jpg|jpeg|png|gif|bmp|tiff|webp)/i.test(fileExtension);
    const isFileAVideo = /(mp4|mov|avi|wmv|mkv|flv|webm)/i.test(fileExtension);
    const isFileADocument = /(pdf|doc|docx|ppt|pptx|xls|xlsx|txt|rtf|odt|ods|odp)/i.test(
        fileExtension,
    );
    const fileType = isFileAPhoto
        ? 'photo'
        : isFileAVideo
        ? 'video'
        : isFileADocument
        ? 'document'
        : 'other';

    // Validate file type
    if (fileType === 'other') {
        console.error('Invalid file type');
        throw new Error('Invalid file type');
    }

    // Prepare request data
    const classes = [INSURANCE_MEDIA_PARAM_PROPERTY_CLASS.replace('{param}', fileType)];
    const requestData = {
        assets: [
            {
                uri: params.url,
                classes: classes,
            },
        ],
    };

    // Prepare request configuration
    const requestConfig = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: requestData,
    };

    // Send API request and return the result
    return await apiHandleRequest(requestConfig);
};

export const getClaimAssets = async params => {
    const url = routes.getClaimAssets
        .replace(':project-id', params.project_id)
        .replace(':claim-id', params.claim_id);

    const request = {
        method: 'GET',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
    };

    return await apiHandleRequest(request);
};

export const claimCreateAsset = async params => {
    const url = routes.insuranceClaimChatHistoryCreateAsset
        .replace(':id', params.app_id)
        .replace(':claim-id', params.claim_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

export const updateCreatedClaimProperties = async params => {
    const url = routes.updateCreatedClaimProperties
        .replace(':application-id', params.app_id)
        .replace(':claim-id', params.claim_id);

    const request = {
        method: 'post',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: params.data ? params.data : {},
    };

    return await apiHandleRequest(request);
};

/**
 * Creates a claim asset based on the file type.
 * @param {Object} params - The parameters for creating the claim asset.
 * @param {string} params.assets - Files in a format {{uri: string, fileName: string}}.
 * @param {string} params.claim_id - The claim ID.
 * @param {string} params.project_id - The project ID.
 * @returns {Promise} A promise that resolves with the result of the API request.
 */

export const createClaimAssetsForMultipleFiles = async params => {
    const url = routes.createClaimAsset
        .replace(':project-id', params.project_id)
        .replace(':claim-id', params.claim_id);

    const formattedAssetsForUpload = params.assets.map(asset => {
        const fileExtension = asset.fileName.split('.').pop();

        const isFileAPhoto = /(jpg|jpeg|png|gif|bmp|tiff|webp)/i.test(fileExtension);
        // const isFileAVideo = /(mp4|mov|avi|wmv|mkv|flv|webm)/i.test(fileExtension);
        // const isFileADocument = /(pdf|doc|docx|ppt|pptx|xls|xlsx|txt|rtf|odt|ods|odp)/i.test(
        //     fileExtension,
        // );
        const fileType = isFileAPhoto
            ? 'photo'
            : // : isFileAVideo
              // ? 'video'
              // : isFileADocument
              // ? 'document'
              'other';

        if (fileType === 'other') {
            console.error('Invalid file type');
            throw new Error('Invalid file type');
        }
        const properClasses = [INSURANCE_MEDIA_PARAM_PROPERTY_CLASS.replace('{param}', fileType)];

        return {
            uri: asset.uri,
            classes: properClasses,
        };
    });

    const requestData = {
        assets: formattedAssetsForUpload,
    };

    const requestConfig = {
        method: 'POST',
        url: url,
        headers: {
            Authorization: apiGetAuthHeader(),
            'Content-Type': 'application/json',
        },
        data: requestData,
    };

    return await apiHandleRequest(requestConfig);
};
