import { postDistributorsMetaData } from 'api/distributors';
import classNames from 'classnames';
import React, { lazy, Suspense, useCallback, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { deleteSession, isUserAuthenticated } from 'services/localStorage';
import {
    userHasPermission,
    userHasThisPermissionOnly,
    userIsDistributor,
    userIsInsurer,
} from 'services/roles';
import { Loading } from 'shared';
import { checkAuthWithRedirect } from 'utils/functions';
import Styles from './PrivateRoute.module.scss';

type IProps = any;

const Header = lazy(() => import('redux/containers/Header'));
const Footer = lazy(() => import('redux/containers/Footer'));
const SideBar = lazy(() => import('redux/containers/SideBar'));

const RegisterFlow = lazy(() => import('pages/Distributors/RegisterFlow'));

const PrivateRoute: React.FC<IProps> = ({ component: Component, roles, ...otherProps }) => {
    const [distributorState, setDistributorState] = React.useState<
        'pending' | 'processing' | 'approved' | undefined
    >(undefined);
    const [shouldShowDistributorRegisterFlow, setShouldShowDistributorRegisterFlow] =
        React.useState<boolean>(false);

    const handleDistributorState: () => Promise<void> = useCallback(async () => {
        const logout = () => {
            deleteSession();
            checkAuthWithRedirect();
        };
        if (userIsDistributor() && !userIsInsurer()) {
            try {
                await postDistributorsMetaData({
                    namespace: 'state',
                })
                    .catch(() => {
                        logout();
                    })
                    .then((data: { state: typeof distributorState }) => {
                        let APIDistributorState: typeof distributorState | undefined = data?.state;
                        let isInvalidDistributorState: boolean =
                            data?.state !== 'pending' &&
                            data?.state !== 'processing' &&
                            data?.state !== 'approved' &&
                            data?.state !== 'active';
                        let validDistributorState: boolean = !isInvalidDistributorState;

                        if (APIDistributorState) {
                            if (validDistributorState) {
                                setDistributorState(APIDistributorState);
                            } else {
                                logout();
                            }
                        } else {
                            logout();
                        }
                    });
            } catch (error) {
                logout();
            }
        }
    }, []);

    useEffect(() => {
        setShouldShowDistributorRegisterFlow(
            userHasPermission([roles.DISTRIBUTOR]) &&
                {
                    pending: 'pending',
                    processing: 'processing',
                    approved: 'approved',
                }.hasOwnProperty(distributorState),
        );
    }, [distributorState, roles.DISTRIBUTOR]);

    useEffect(() => {
        handleDistributorState();
    }, [handleDistributorState]);

    useEffect(() => {
        return () => {
            setDistributorState(undefined);
        };
    }, []);

    const logout = () => {
        return (
            <Redirect
                to={{
                    pathname: '/logout',
                    search: `?callbackUrl=${otherProps.location.pathname}`,
                }}
            />
        );
    };

    if (!isUserAuthenticated()) {
        return logout();
    }

    let userDoesNotHavePermissions =
        (otherProps.uniqueRole && !userHasThisPermissionOnly(otherProps.scope)) ||
        !userHasPermission(otherProps.scope);

    if (userDoesNotHavePermissions) {
        if (!otherProps.bypassScope || typeof otherProps.bypassScope !== 'function') {
            console.error('User does not have permission to access this route');
            return (
                <Redirect
                    to={{
                        pathname: '/',
                    }}
                />
            );
        }
        if (typeof otherProps.bypassScope === 'function' && !otherProps.bypassScope()) {
            console.error('User does not have permission to access this route');
            return (
                <Redirect
                    to={{
                        pathname: '/',
                    }}
                />
            );
        }
    }

    return (
        <Route
            {...otherProps}
            render={props =>
                otherProps.sidebar.visible ? (
                    <>
                        <Header type={otherProps.header.section} />
                        <main
                            role="main"
                            className={classNames(Styles.main, Styles[otherProps.header.section])}
                        >
                            <SideBar
                                disabled={otherProps.sidebar.disabled}
                                section={otherProps.sidebar.section}
                                {...props}
                            />
                            <Suspense fallback={<Loading theme="full" />}>
                                {shouldShowDistributorRegisterFlow ? (
                                    <RegisterFlow status={distributorState} />
                                ) : (
                                    <Component {...props} />
                                )}
                            </Suspense>
                        </main>
                        <Footer />
                    </>
                ) : (
                    <>
                        <Header type={otherProps.header.section} />
                        <Suspense fallback={<Loading theme="full" />}>
                            {shouldShowDistributorRegisterFlow ? (
                                <RegisterFlow status={distributorState} />
                            ) : (
                                <Component {...props} />
                            )}
                        </Suspense>
                        <Footer />
                    </>
                )
            }
        />
    );
};

export default PrivateRoute;
