import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Loading from 'shared/Loading';
import Popup from 'shared/Popup';
import { getElementPosition } from 'utils/functions';
import Styles from './Search.module.scss';

export class Search extends React.PureComponent {
    myRef = React.createRef();

    state = {
        focus: true,
        blur: null,
        save: false,
        popup: {
            id: 'popupTempId',
            active: false,
            element: {},
            selectedId: null,
        },
    };

    static propTypes = {
        popupId: PropTypes.string,
        withFilter: PropTypes.bool,
        filters: PropTypes.object,
        filterDisabled: PropTypes.bool,
        theme: PropTypes.oneOf(['primary', 'secondary', 'ternary']),
        variant: PropTypes.oneOf(['none', 'outlined', 'filled']),
        state: PropTypes.oneOf(['empty', 'active', 'error', 'open', 'close']),
        size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
        namespace: PropTypes.string,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        autoFocus: PropTypes.bool,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        dropdownPosition: PropTypes.string,
        name: PropTypes.string,
        onClick: PropTypes.func,
        onSearch: PropTypes.func,
        onClear: PropTypes.func,
        onSelect: PropTypes.func,
        doBlur: PropTypes.bool,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        doFocus: PropTypes.bool,
        onChange: PropTypes.func,
        inputRef: PropTypes.object,
        onConfirm: PropTypes.func,
        customIcon: PropTypes.string,
    };

    static defaultProps = {
        theme: 'primary',
        size: 'md',
        doBlur: false,
    };

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        if (this.props.popupId) {
            this.setState(state => ({
                popup: {
                    ...state.popup,
                    id: this.props.popupId,
                },
            }));
        }

        if (this.props.autoFocus) {
            setTimeout(() => {
                if (this.myRef && this.myRef.current) {
                    this.myRef.current.focus();
                }
            }, 100);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.myRef && this.myRef.current && !prevProps.doBlur && this.props.doBlur) {
            this.myRef.current.blur();
        }

        if (this.myRef && this.myRef.current && !prevProps.doFocus && this.props.doFocus) {
            this.myRef.current.focus();
        }
    }

    handleOnFocus = e => {
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    handleOnBlur = e => {
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    handleTogglePopup = e => {
        e.preventDefault();
        const target = e.currentTarget;

        this.setState(state => ({
            popup: {
                ...state.popup,
                active: !state.popup?.active,
                element: getElementPosition(target),
            },
        }));
    };

    handleClosePopup = () => {
        this.setState(state => ({
            popup: {
                ...state.popup,
                active: false,
            },
        }));
    };

    handleOnSelect = e => {
        const id = e.target.getAttribute('data-id');
        this.props.onSelect(id);
        this.handleClosePopup();
    };

    _handleKeyDown = event => {
        let ENTER = 13;
        switch (event.keyCode) {
            case ENTER:
                if (this.props.onConfirm) {
                    this.props.onConfirm(this.props.value);
                }
                break;
            default:
                break;
        }
    };

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    getContent() {
        if (this.props.filters) {
            const { list, selected } = this.props.filters;

            return (
                <ul className={Styles.dropdown}>
                    {list.length > 0 &&
                        list.map(item => (
                            <li
                                className={item.id === selected ? Styles?.active : null}
                                key={item.id}
                                data-id={item.id}
                                onClick={this.handleOnSelect}
                            >
                                {item.text}
                            </li>
                        ))}
                </ul>
            );
        }
    }

    render() {
        const {
            theme,
            variant,
            state,
            size,
            withFilter,
            filters,
            namespace,
            disabled,
            autoFocus,
            onClick,
            onChange,
            onClear,
            placeholder,
            value,
            name,
            dropdownPosition,
            filterDisabled,
            onSearch,
            loading,
            inputRef,
            customIcon,
        } = this.props;
        const { popup } = this.state;

        const filter =
            filters && filters.list[filters.selected] && filters.list[filters.selected].text
                ? filters.list[filters.selected].text
                : null;

        return (
            <div
                data-test="SearchContainer"
                className={classnames(Styles.container, Styles[theme], Styles[size], Styles[state])}
            >
                {withFilter && (
                    <button
                        data-test="SearchFilter"
                        type="button"
                        className={classnames(
                            Styles.filters,
                            Styles[theme],
                            Styles[variant],
                            Styles[size],
                        )}
                        onClick={this.handleTogglePopup}
                        disabled={filterDisabled}
                        aria-disabled={filterDisabled}
                    >
                        {filter}
                        <i
                            className={
                                popup?.active ? 'icon-icon_arrow_up' : 'icon-icon_arrow_down'
                            }
                        ></i>
                    </button>
                )}

                <label className={Styles.wrapper}>
                    <input
                        autoComplete="off"
                        data-test="SearchField"
                        data-testid="SearchField"
                        data-namespace={namespace}
                        autoFocus={autoFocus}
                        name={name}
                        disabled={disabled || loading}
                        aria-disabled={disabled}
                        ref={inputRef ? inputRef : this.myRef}
                        onChange={onChange}
                        onClick={onClick}
                        placeholder={placeholder}
                        value={value}
                        type="search"
                        className={classnames(
                            Styles.input,
                            Styles[theme],
                            Styles[variant],
                            Styles[size],
                            Styles[state],
                            withFilter ? Styles.withFilter : null,
                        )}
                        onFocus={this.handleOnFocus}
                        onBlur={this.handleOnBlur}
                    />

                    {!loading && (
                        <i
                            data-test="SearchIcon"
                            className={classnames(
                                Styles.action,
                                Styles.icon,
                                Styles[theme],
                                Styles[variant],
                                Styles[size],
                                Styles[state],
                                onClear && value !== ''
                                    ? 'icon-icon_delete_2'
                                    : customIcon
                                    ? customIcon
                                    : 'icon-icon_search',
                                'searchIcon',
                            )}
                            onClick={
                                onClear && value !== ''
                                    ? onClear
                                    : onSearch
                                    ? onSearch
                                    : this.handleOnFocus
                            }
                        ></i>
                    )}

                    {loading && (
                        <div className={Styles.loading}>
                            <Loading
                                className={classnames(
                                    Styles.icon,
                                    Styles[theme],
                                    Styles[variant],
                                    Styles[size],
                                    Styles[state],
                                )}
                                variant="xs"
                            />
                        </div>
                    )}
                </label>

                <Popup
                    id={popup.id || `popupTempId`}
                    position={dropdownPosition ? dropdownPosition : 'bottom'}
                    size="md"
                    active={popup?.active}
                    element={popup.element}
                    closePopup={this.handleClosePopup}
                    content={this.getContent()}
                />
            </div>
        );
    }
}

export default Search;
