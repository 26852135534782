import AppProvider from 'AppProvider';
import ErrorPageWithoutRouter from 'pages/ErrorPageWithoutRouter';
import PropTypes from 'prop-types';
import CampaignsProvider from 'providers/CampaignsProvider';
import React, { useCallback, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import Router from 'redux/containers/App';
import { Loading } from 'shared';
import { SELFCARE_AVAILABLE_LANGUAGES } from 'utils/constants';
import { isDevEnv, isQaEnv, isStgEnv } from 'utils/functions';

const App = ({ store, history }) => {
    const [languages, setLanguagesState] = useState<
        | {
              [key in (typeof SELFCARE_AVAILABLE_LANGUAGES)[number]]: JSON;
          }
        | undefined
    >(undefined);
    const [error, setError] = useState(false);

    const setLanguages = (languages: {
        [key in (typeof SELFCARE_AVAILABLE_LANGUAGES)[number]]: JSON;
    }) => {
        if (!localStorage.getItem('languages')) window.location.reload();
        localStorage.setItem('languages', JSON.stringify(languages));
        setLanguagesState(languages);
    };

    const getCopies = async () => {
        let baseURL = 'https://cdn.habit.io';
        if (isDevEnv() || isStgEnv() || isQaEnv()) {
            baseURL = 'https://cdn.integrations.habit.io';
        }
        try {
            return await Promise.allSettled(
                SELFCARE_AVAILABLE_LANGUAGES.map(lang => {
                    return {
                        data: fetch(`${baseURL}/insurance-products/selfcare/copy/${lang}.json`, {
                            cache: 'no-store',
                        }),
                        language: lang,
                    };
                }),
            );
        } catch (error) {
            console.log(error);
            setError(true);
        }
    };

    const handleGetLanguages = useCallback(async () => {
        const shouldLoadLocalConfigFile = process.env.NODE_ENV === 'development';
        // const shouldLoadLocalConfigFile = true;

        const loadConfigFile: () => void = () => {
            const developmentModeLanguagesObject: {
                [key in (typeof SELFCARE_AVAILABLE_LANGUAGES)[number]]: JSON;
            } = {
                en: require('./locales/en.json'),
                pt: require('./locales/pt.json'),
                es: require('./locales/es.json'),
            };
            setLanguages(developmentModeLanguagesObject);
        };

        const loadRemoteFile: (
            res: PromiseSettledResult<{
                data: Promise<Response>;
                language: string;
            }>[],
        ) => Promise<void> = async res => {
            try {
                const productionModeLanguagesObject = await Promise.all(
                    res.map(async p => {
                        if (p.status === 'fulfilled') {
                            const value = await (await p.value.data).json();
                            const language = await p.value.language;
                            return {
                                [language]: value,
                            };
                        }
                    }),
                ).then(data =>
                    data.reduce((acc, cur) => {
                        return { ...acc, ...cur };
                    }),
                );

                setLanguages(productionModeLanguagesObject);
            } catch (error) {
                console.log(error);
                setError(true);
            }
        };

        if (shouldLoadLocalConfigFile) {
            loadConfigFile();
        } else {
            await getCopies().then(res => {
                loadRemoteFile(res);
            });
        }
    }, []);

    useEffect(() => {
        handleGetLanguages();
    }, [handleGetLanguages]);
    return error ? (
        <ErrorPageWithoutRouter />
    ) : !languages ? (
        <Loading theme="full" />
    ) : (
        <Provider store={store}>
            <AppProvider>
                <CampaignsProvider>
                    <Router history={history} />
                </CampaignsProvider>
            </AppProvider>
        </Provider>
    );
};

App.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default App;
