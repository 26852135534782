import { AppContext } from 'AppProvider';
import classnames from 'classnames';
import CardItem from 'components/Networks/BillingTable/CardItem';
import isObject from 'isobject';
import { doesCurrentKeyExistsInCurrentLanguage, getText } from 'localization';
import get from 'lodash.get';
import moment from 'moment';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'shared/Avatar';
import DropdownMultiselect from 'shared/DropdownMultiselect';
import Loading from 'shared/Loading';
import Popup from 'shared/Popup';
import { DATE_FORMAT_NO_TIME_INTERNATIONAL, PORTAL_TYPES } from 'utils/constants';
import {
    convertUTCtoLocalDateAndHoursString,
    copyToClipboard,
    formatDate,
    getElementPosition,
    objectToString,
} from 'utils/functions';
import Header from './Header';
import Styles from './Table.module.scss';

const Table = ({
    texts,
    data,
    isLoading,
    isSearch,
    filters,
    pagination,
    onClickPrev,
    onClickNext,
    onClickStart,
    onClickEnd,
    header,
    section,
    projectId,
    hasViewMore = true,
    emptyText,
    onEdit,
    onSort,
    hasBorderTop = false,
    guardAgainstInnacurateClassNames,
    featureFlag,
    setProducts,
    searchValue,
}) => {
    const [state, setState] = React.useState({
        moreInfo: {
            active: false,
            element: {},
            content: null,
        },
    });

    const {
        admin: {
            products: {
                distributors: {
                    addProductsModal: { checkedProducts, setCheckedProducts },
                },
            },
        },
    } = useContext(AppContext);

    const handleOnSort = e => {
        const field = e.target.getAttribute('data-field');
        const direction = e.target.getAttribute('data-direction');
        const inverse = e.target.getAttribute('data-inverse');

        const params = {
            field: field,
            direction: inverse ? (direction === 'asc' ? 'desc' : 'asc') : direction,
        };

        if (field && direction && onSort) {
            onSort(params);
        }
    };

    function filterUsers() {
        if (searchValue) {
            const value = searchValue.toUpperCase();

            const output = data.filter(user => {
                const email = user.email.toUpperCase();

                return email.indexOf(value) !== -1;
            });

            return output;
        }

        return data;
    }

    const searchResults = filterUsers();

    const formatDisplayValueField = (field, value, title = false) => {
        if (
            field.field === 'policy' &&
            field.name === getText('insurance_claim_table_headers_converted_into_policy')
        ) {
            return value && value !== ''
                ? getText('common_uppercase_yes')
                : getText('common_uppercase_no');
        }

        if (
            field.field === 'submission_date' ||
            field.field === 'policy_start_date' ||
            field.field === 'enrolment_date' ||
            field.field === 'active_ts'
        ) {
            if (title) {
                return moment(value).format('YYYY-MM-DD HH:mm:ss') + ' (UTC)';
            }
            return value ? formatDate(value, null, DATE_FORMAT_NO_TIME_INTERNATIONAL, false) : '--';
        }
        if (field.field === 'status') {
            let hasFeatureToggleForTranslation = Boolean(featureFlag);
            if (hasFeatureToggleForTranslation) {
                let parsedValue = `${featureFlag}_${value}`.toLowerCase().replace(/ /g, '-');
                if (doesCurrentKeyExistsInCurrentLanguage(parsedValue)) {
                    return getText(parsedValue);
                } else {
                    return value;
                }
            } else {
                return value;
            }
        }
        return value && value !== '' ? value : '--';
    };

    const handleOnEdit = e => {
        const id = e.currentTarget.getAttribute('data-id');
        onEdit({ id: id });
    };

    const handleToggleMoreInfo = (e, payload) => {
        setState({
            moreInfo: {
                active: !state.moreInfo?.active,
                element: getElementPosition(e.target),
                content: payload.item,
            },
        });
    };

    const handleTogglePaymentInfo = (e, item) => {
        const payload = {
            payment_id: item.id,
            params: data,
            item: item,
        };

        if (typeof handleToggleMoreInfo === 'function') {
            handleToggleMoreInfo(e, payload);
        }
    };

    const handleCopyToClipboard = e => {
        const value =
            e.currentTarget.getAttribute('data-value') || e.currentTarget.getAttribute('data-str');
        copyToClipboard(value);
    };

    const shownInfoIfExists = [
        {
            label: getText(
                'distribution_point_label_im_table_popup_shown_info_on_additional_information_of_sale',
            ),
            value: 'distributionpoint.name',
        },
        {
            label: getText(
                'lead_label_im_table_popup_shown_info_on_additional_information_of_sale',
            ),
            value: 'lead.name',
        },
        {
            label: getText(
                'lead_label_im_table_popup_shown_info_on_additional_information_of_sale_email',
            ),
            value: 'lead.email',
        },
    ];

    const shownInfoIndependentlyIfExists = [
        {
            label: getText(
                'seller_label_im_table_popup_shown_info_on_additional_information_of_sale',
            ),
            value: 'seller.name',
        },
        {
            label: getText(
                'seller_label_im_table_popup_shown_info_on_additional_information_of_sale_email',
            ),
            value: 'seller.email',
        },
    ];

    function getMoreInfoPopupContent() {
        return (
            <div className={Styles.moreInfoContent}>
                <div>
                    <ul>
                        {shownInfoIfExists?.map((item, key) => {
                            let { label, value } = item;
                            const getValueFromObj = get(state.moreInfo.content, value);
                            if (!getValueFromObj) return null;
                            return (
                                <React.Fragment key={key}>
                                    <CardItem
                                        label={label}
                                        value={getValueFromObj}
                                        onClick={handleCopyToClipboard}
                                        dataTip={getText('copy')}
                                        hasCopyButton={true}
                                        isParagraph={true}
                                    />
                                </React.Fragment>
                            );
                        })}
                        {shownInfoIndependentlyIfExists?.map((item, key) => {
                            let { label, value } = item;
                            const getValueFromObj = get(state.moreInfo.content, value);
                            return (
                                <React.Fragment key={key}>
                                    <CardItem
                                        label={label}
                                        value={getValueFromObj || '--'}
                                        onClick={handleCopyToClipboard}
                                        dataTip={getText('copy')}
                                        hasCopyButton={!!getValueFromObj}
                                        isParagraph={true}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    }

    const getExceptionColWidthAdjustmentsClasses = col => {
        if (
            col.field === 'checked' &&
            guardAgainstInnacurateClassNames === 'admin_distributor_modal_authorized_products'
        ) {
            return Styles['first-item-sssm'];
        }

        if (
            col.field === 'seller_point_info' &&
            guardAgainstInnacurateClassNames === 'insurance_quotes'
        ) {
            return Styles['seller-point-info'];
        }

        if (
            col.field === 'seller_point_info' &&
            guardAgainstInnacurateClassNames === 'insurance_policies'
        ) {
            return Styles['seller-point-info'];
        }
    };

    const getAvatar = (col, item) => {
        return (
            col.field === 'name' &&
            col.hasIcon && (
                <Avatar random={false} color="#2d426b" text={item[col.field]} hasIcon icon="user" />
            )
        );
    };

    const getIcon = (col, item) => {
        return (
            (col.field === 'type' || col.field === 'segment' || col.field === 'service') &&
            col.hasIcon && (
                <Avatar
                    random={false}
                    color="#2d426b"
                    text={item[col.field]}
                    hasIcon
                    icon={item?.segment ? item.segment : item[col.field]}
                />
            )
        );
    };

    const getStatusException = (col, item) => {
        return col.field === 'status'
            ? Styles[formatDisplayValueField(col, item[col.field])]
            : null;
    };

    const getValueComponent = (col, item) => {
        if (col.component) {
            return col.component({
                col: col,
                item: item,
            });
        }
        const shouldApplyFlexCol =
            col.field === 'service' &&
            (guardAgainstInnacurateClassNames === 'insurance_quotes' ||
                guardAgainstInnacurateClassNames === 'insurance_policies');
        return (
            <p
                className={classnames(
                    shouldApplyFlexCol ? Styles.flexcolumn : Styles.flexrow,
                    Styles[col.field],
                    getStatusException(col, item),
                )}
                title={formatDisplayValueField(col, item[col.field], true)}
            >
                {col.field === 'enrolment_date' &&
                guardAgainstInnacurateClassNames === 'insurance_policies' ? (
                    <div className={Styles.withSubtitle}>
                        <span>
                            {convertUTCtoLocalDateAndHoursString(item.enrolment_date, 'date_only')}
                        </span>
                        <span className={Styles.subtitle}>
                            {convertUTCtoLocalDateAndHoursString(
                                item.enrolment_date,
                                'time&timezone',
                            )}
                        </span>
                    </div>
                ) : col.field === 'origin' &&
                  (guardAgainstInnacurateClassNames === 'insurance_quotes' ||
                      guardAgainstInnacurateClassNames === 'insurance_policies') ? (
                    <div className={Styles.insurance_quotes_origin}>
                        <div>
                            {item.lead?.name ? 'Lead' : item.seller?.name ? 'Seller' : 'Guest'}
                        </div>
                    </div>
                ) : col.field === 'seller_point_info' &&
                  (guardAgainstInnacurateClassNames === 'insurance_quotes' ||
                      guardAgainstInnacurateClassNames === 'insurance_policies') ? (
                    <div className={Styles.insurance_quotes_seller_point_info}>
                        <div className={Styles.title}>
                            {item.lead?.name
                                ? item.lead.name
                                : item.seller?.name
                                ? item.seller.name
                                : '--'}
                        </div>
                        <div className={Styles.subtitle}>
                            {item.lead?.name || item.seller?.name
                                ? item.distributionpoint?.name
                                    ? item.distributionpoint.name
                                    : '--'
                                : '--'}
                        </div>
                    </div>
                ) : (
                    formatDisplayValueField(col, item[col.field])
                )}
            </p>
        );
    };

    const updateCheckedProductsAuthorizedDistributionFlows = (product, distribution_flows) => {
        const updatedProducts = checkedProducts.map(c => {
            if (c.app_id === product.app_id) {
                c.allowed_distribution_flows = distribution_flows;
            }
            return c;
        });
        setCheckedProducts(updatedProducts);
    };

    const getDistributionFlows = (setProducts, product_id) => {
        const currentProduct = searchResults.find(product => product.id === product_id);
        return (
            <DropdownMultiselect
                id="portalType"
                namespace="portalType"
                name="portalType"
                data={PORTAL_TYPES ? PORTAL_TYPES : null}
                position={'bottom'}
                isCustomPosition={false}
                theme="select"
                variant="outlined"
                placeholder={'None'}
                size={'md'}
                onChange={({ id }) => {
                    const mappedData = searchResults.map(product => {
                        if (product.id === product_id) {
                            if (product.allowed_distribution_flows.includes(id)) {
                                product.allowed_distribution_flows =
                                    product.allowed_distribution_flows.filter(flow => flow !== id);
                            } else {
                                product.allowed_distribution_flows = [
                                    ...product.allowed_distribution_flows,
                                    id,
                                ];
                            }
                            updateCheckedProductsAuthorizedDistributionFlows(
                                product,
                                product.allowed_distribution_flows,
                            );
                            return product;
                        }
                        updateCheckedProductsAuthorizedDistributionFlows(
                            product,
                            product.allowed_distribution_flows,
                        );
                        return product;
                    });
                    const currentCheckedP = {
                        allowed_distribution_flows: currentProduct.allowed_distribution_flows,
                        app_id: currentProduct.app_id,
                        insurer_id: currentProduct.insurer_id,
                        _id: currentProduct._id,
                    };

                    const mappedCheckedProducts = checkedProducts.find(
                        product => product.app_id === currentProduct.app_id,
                    )
                        ? checkedProducts
                        : [...checkedProducts, currentCheckedP];

                    setCheckedProducts(mappedCheckedProducts);
                    setProducts(mappedData);
                }}
                onSelectAll={() => {
                    const mappedData = searchResults.map(product => {
                        if (product.id === product_id) {
                            if (product.allowed_distribution_flows.length === PORTAL_TYPES.length) {
                                product.allowed_distribution_flows = [];
                                return product;
                            }
                            product.allowed_distribution_flows = PORTAL_TYPES.map(type => type.id);
                            updateCheckedProductsAuthorizedDistributionFlows(
                                product,
                                product.allowed_distribution_flows,
                            );
                            return product;
                        }
                        updateCheckedProductsAuthorizedDistributionFlows(
                            product,
                            product.allowed_distribution_flows,
                        );
                        return product;
                    });
                    const currentCheckedP = {
                        allowed_distribution_flows: currentProduct.allowed_distribution_flows,
                        app_id: currentProduct.app_id,
                        insurer_id: currentProduct.insurer_id,
                        _id: currentProduct._id,
                    };
                    const mappedCheckedProducts = checkedProducts.find(
                        product => product.app_id === currentProduct.app_id,
                    )
                        ? checkedProducts
                        : [...checkedProducts, currentCheckedP];

                    setCheckedProducts(mappedCheckedProducts);
                    setProducts(mappedData);
                }}
                onClearAll={() => {
                    const mappedData = searchResults.map(product => {
                        if (product.id === product_id) {
                            product.allowed_distribution_flows = [];
                        }
                        updateCheckedProductsAuthorizedDistributionFlows(
                            product,
                            product.allowed_distribution_flows,
                        );
                        return product;
                    });
                    setProducts(mappedData);
                }}
                isLoading={isLoading}
                disabled={isLoading}
                block={true}
                withSearch={false}
                selected={currentProduct.allowed_distribution_flows || []}
                hasDoneButton={false}
                maxWidth={undefined}
                onDone={undefined}
                withValidation={undefined}
                isValid={undefined}
                unsaved={undefined}
                itemKey={undefined}
                hasEmptyState={undefined}
            />
        );
    };

    return (
        <div data-test="Container" className={classnames(Styles.table, Styles[section])}>
            <Header
                data-test="Header"
                data={header}
                handleOnSort={onSort ? handleOnSort : null}
                isSearch={isSearch}
                texts={texts}
                filters={filters}
                pagination={pagination}
                onClickPrev={onClickPrev}
                onClickNext={onClickNext}
                onClickStart={onClickStart}
                onClickEnd={onClickEnd}
                hasBorderTop={hasBorderTop}
                guardAgainstInnacurateClassNames={guardAgainstInnacurateClassNames}
                featureFlag={featureFlag}
            />
            <div data-test="Table" className={Styles.content}>
                {isLoading && <Loading theme="none" variant="sm" />}
                {!isLoading &&
                    data?.length > 0 &&
                    searchResults?.map((item, i) => (
                        <div data-test="Row" key={item.id ? item.id : i} className={Styles.row}>
                            <>
                                {header &&
                                    header.map(col => (
                                        <div
                                            key={col.id}
                                            className={classnames(
                                                Styles.col,
                                                Styles[col.field],
                                                getExceptionColWidthAdjustmentsClasses(col),
                                            )}
                                        >
                                            {col.field === 'allowed_distribution_flows' ? (
                                                getDistributionFlows(
                                                    setProducts,
                                                    searchResults?.[i]?.id,
                                                )
                                            ) : (
                                                <>
                                                    {col.field === 'seller_point_info' &&
                                                        (guardAgainstInnacurateClassNames ===
                                                            'insurance_quotes' ||
                                                            guardAgainstInnacurateClassNames ===
                                                                'insurance_policies') &&
                                                        (shownInfoIfExists?.find(s =>
                                                            get(item, s.value),
                                                        ) ||
                                                            shownInfoIndependentlyIfExists?.find(
                                                                s => get(item, s.value),
                                                            )) && (
                                                            <i
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '0',
                                                                    top: '0',
                                                                    cursor: 'pointer',
                                                                    fontSize: '1.75rem',
                                                                }}
                                                                className={classnames(
                                                                    'icon-icon_about_3',
                                                                )}
                                                                onClick={e => {
                                                                    handleTogglePaymentInfo(
                                                                        e,
                                                                        item,
                                                                    );
                                                                }}
                                                            ></i>
                                                        )}
                                                    {!isObject(item[col.field]) && (
                                                        <>
                                                            {getAvatar(col, item)}
                                                            {getIcon(col, item)}
                                                            {getValueComponent(col, item)}
                                                        </>
                                                    )}
                                                    {isObject(item[col.field]) && (
                                                        <>
                                                            <p className={Styles[col.field]}>
                                                                {objectToString(item[col.field])}
                                                            </p>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    ))}
                                <div className={classnames(Styles.col, Styles.actions)}>
                                    {hasViewMore && (
                                        <Link
                                            className={Styles.details}
                                            to={`/insurance/${section}/${item.id}/app/${projectId}`}
                                        >
                                            {getText('common_view_more')}
                                        </Link>
                                    )}
                                    {onEdit && (
                                        <span
                                            data-id={item.id}
                                            className={Styles.details}
                                            onClick={handleOnEdit}
                                        >
                                            {getText('common_view_more')}
                                        </span>
                                    )}
                                </div>
                            </>
                        </div>
                    ))}

                {!isLoading && (!data || data.length === 0) && (
                    <div data-test="Empty" className={Styles.empty}>
                        <p>{emptyText ? emptyText : getText('end_users_no_users_found')}</p>
                    </div>
                )}

                {!isLoading && data && data.length > 0 && searchResults.length === 0 && (
                    <p data-test="NoResults" className={Styles.notFound}>
                        {getText('no_results')}
                    </p>
                )}

                <Popup
                    data-test="PaymentInfoPopup"
                    position="bottom"
                    id="paymentInfoPopup"
                    active={state.moreInfo?.active}
                    element={state.moreInfo.element}
                    closePopup={() => {
                        setState({
                            moreInfo: {
                                active: false,
                                element: {},
                                content: null,
                            },
                        });
                    }}
                    content={getMoreInfoPopupContent()}
                />
            </div>
        </div>
    );
};

export default Table;
