import { Row } from 'components/Documents';
import { CLASSES, Feature, InputField } from 'hooks/useDynamicFields';
import { getText } from 'localization';
import moment from 'moment';
import { roles, userHasPermission, userHasThisPermissionOnly } from 'services/roles';
import { sendErrorToast } from 'services/toast';
import {
    DistributorDocumentsPayload,
    DocumentsFeature,
    ProductDocumentsPayload,
    ProviderDocumentsPayload,
} from 'types/global';
import { INSURANCE_PAYMENTS_STATUS_LIST, READONLY_PAYMENT_STATUS_LIST } from './constants';
import {
    convertUTCtoLocalDateAndHoursString,
    isB2B,
    isLocalEnv,
    isMediPet,
    isSelfcare,
} from './functions';

export const getSpecifics = (classes: string[]) => {
    return {
        hasEditableNull: classes.some((str: string) => str === CLASSES.editable_null),
        hasEditableManagement: classes.some((str: string) => str === CLASSES.editable_management),
        hasImmutable: classes.some((str: string) => str === CLASSES.immutable),
        hasPlatformManagement: classes.some((str: string) => str === CLASSES.platform_management),
    };
};

export const getIsEditable = (item: InputField, feature: Feature): boolean => {
    const classes = item?.classes || [];
    const isNullOrEmptyString: boolean = !item.data;
    const shouldTemporarilyNotDisableInput = item.shouldTemporarilyNotDisableInput;

    const { hasEditableNull, hasEditableManagement, hasImmutable, hasPlatformManagement } =
        getSpecifics(classes);

    const accordsToEditableNullConditions =
        hasEditableNull && (isNullOrEmptyString || shouldTemporarilyNotDisableInput);
    const accordsToEditableManagementConditions = hasEditableManagement && !hasEditableNull;
    const accordsToImmutableConditions = !hasImmutable;

    switch (feature) {
        case Feature.policyholder:
        case Feature.protectedAsset:
            return accordsToEditableNullConditions || accordsToEditableManagementConditions;
        case Feature.claims:
            return (
                (accordsToEditableNullConditions ||
                    accordsToEditableManagementConditions ||
                    hasPlatformManagement) &&
                accordsToImmutableConditions
            );
        default:
            return false;
    }
};

export const getTimezone: () => string = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || '';
};

export const getDateWithTimezone = (date: Date) => {
    if (!date) return;
    return date + ': ' + getTimezone();
};

export async function fetchWithExponentialBackoff(
    apiCall: () => Promise<any>,
    acceptCondition: (response) => Promise<boolean>,
    maxRetries = 6,
    baseDelay = 1000,
) {
    let retries = 0;

    async function retry() {
        console.log('Response did not meet the condition. Retrying...');
        retries++;
        const delay = Math.pow(2, retries - 1) * baseDelay;
        await new Promise(resolve => setTimeout(resolve, delay));
    }

    while (retries < maxRetries) {
        try {
            const response = await apiCall();
            if (await acceptCondition(response)) {
                return response;
            } else {
                await retry();
            }
        } catch (error) {
            throw error;
        }
    }
    sendErrorToast('Max retries reached. Please try again later!');
}

export const isInvalidURIInPayload = (payload: Array<Row>) => {
    return payload?.some(row => !row.uri);
};
export const createPayload = <T extends Row>(
    rows: Array<T>,
    feature: DocumentsFeature,
): {
    documents:
    | ProductDocumentsPayload['documents']
    | DistributorDocumentsPayload['documents']
    | ProviderDocumentsPayload['documents'];
} => {
    return {
        documents: (rows || []).reduce((acc, row) => {
            if (!row.uri) {
                return acc;
            }

            let output:
                | ProductDocumentsPayload['documents'][number]
                | DistributorDocumentsPayload['documents'][number]
                | ProviderDocumentsPayload['documents'][number];

            if (feature === 'product-documents') {
                output = {
                    name: row.name,
                    description: row.description,
                    to_be_shown: row.to_be_shown,
                    uri: row.uri,
                    state: 'active',
                    ...(row.id.indexOf('temp-') === -1 && { id: row.id }),
                } as ProductDocumentsPayload['documents'][number];
            }

            if (feature === 'distributor-documents') {
                output = {
                    name: row.name,
                    description: row.description,
                    uri: row.uri,
                    tags: [row.tags?.[0]],
                    ...(row.id.indexOf('temp-') === -1 && { id: row.id }),
                } as DistributorDocumentsPayload['documents'][number];
            }

            if (feature === 'provider-documents') {
                output = {
                    name: row.name,
                    description: row.description,
                    uri: row.uri,
                    tags: [row.tags?.[0]],
                    ...(row.id.indexOf('temp-') === -1 && { id: row.id }),
                } as ProviderDocumentsPayload['documents'][number];
            }

            acc.push(output);
            return acc;
        }, []),
    };
};

export const getCurrencySymbol = (areTheCurrenciesDifferent, projectCurrencySymbol) => {
    if (areTheCurrenciesDifferent) {
        return '€';
    } else {
        return projectCurrencySymbol;
    }
};

export const getFullDateUTC = date => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss') + ' (UTC)';
};

/**
 * This function will be responsible for parsing the data and returning those fields that are needed for the table
 * @param {Object} row - The row object that contains the field that we want to get the value from
 * @example row = {field: 'start_date', customStateFields: {active: 'start_date', canceled: 'canceled_ts'}}
 */
export const getFieldValue = (
    row: {
        field:
        | 'start_date'
        | 'canceled_ts'
        | 'paid_ts'
        | 'error_ts'
        | 'refunded_ts'
        | 'payment_type'
        | 'state'
        | 'amount'
        | 'amount_paid'
        | string;
        customStateFields?: { [key: string]: string };
    },
    value: string | number,
    item: {
        [key: string]: any;
    },
    isCapitalizedText: boolean,
    isTitle: boolean,
    areTheCurrenciesDifferent: boolean,
    projectCurrencySymbol: string,
) => {
    let finalAttr = row.field; // emission_ts

    if (row?.customStateFields) {
        if (item) {
            finalAttr = row.customStateFields[item.state];
            value = item[row.customStateFields[item.state]];
        }
    }

    let isADateFieldGuard = row.field === getText('common_date') || row.field === 'start_date';
    let hasNoValue = !value && value !== 0; // Except 0, since it's a valid value in some cases, such as `amount` and `amount_paid`
    let isDateFallbackNeeded = hasNoValue && isADateFieldGuard;
    if (!isDateFallbackNeeded && isADateFieldGuard && isTitle) {
        return getFullDateUTC(value);
    }
    if (isDateFallbackNeeded) {
        (function fallback() {
            value = item?.start_date ? item?.start_date : '--';
        })();
    }

    switch (finalAttr) {
        case 'start_date':
        case 'canceled_ts':
        case 'paid_ts':
        case 'error_ts':
        case 'refunded_ts':
        case 'emission_ts':
            return value
                ? isTitle
                    ? getFullDateUTC(value)
                    : convertUTCtoLocalDateAndHoursString(value, 'date_only')
                : '--';
        case 'payment_type': {
            if (value) {
                let parsePaymentType = () => {
                    if (value === 'rate_base') {
                        return getText('common_rate_base');
                    } else if (value === 'sepa_debit') {
                        return getText('shared_sepa_debit');
                    } else {
                        return value;
                    }
                };
                value = parsePaymentType();
            }
            return value && value !== '' ? value : '--';
        }
        case 'state':
            let itemHasState = item?.state;
            if (itemHasState) {
                const mockedInsurancePaymentStatus = value
                    ? [...INSURANCE_PAYMENTS_STATUS_LIST, ...READONLY_PAYMENT_STATUS_LIST].filter(
                        insurance => insurance.id === value,
                    )
                    : null;
                let firstMockedInsurance = mockedInsurancePaymentStatus
                    ? mockedInsurancePaymentStatus[0]
                    : null;

                if (Boolean(firstMockedInsurance)) {
                    return isCapitalizedText
                        ? mockedInsurancePaymentStatus[0].id
                        : mockedInsurancePaymentStatus[0].text;
                } else {
                    return item?.state ? item?.state : '--';
                }
            }
            break;
        case 'amount':
        case 'amount_paid': {
            value = `${value || 0} ${getCurrencySymbol(
                areTheCurrenciesDifferent,
                projectCurrencySymbol,
            )}`;
            // @ts-ignore
            return (value || value === 0) && value !== '' ? value : '--';
        }
        default:
            return (value || value === 0) && value !== '' ? value : '--';
    }
};

export const mockRequest = <T>(
    data: T,
    options: {
        status: number;
        statusText?: string;
        headers?: Record<string, string>;
    },
    sleep: number | undefined = 200,
): Promise<Response> => {
    const headers: Record<string, string> = {
        'Content-type': 'application/json',
        ...(options.headers || {}),
    };

    const response = new Response(JSON.stringify(data), {
        status: options.status,
        headers,
    });

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (options.status >= 200 && options.status < 300) {
                resolve(response);
            } else {
                reject(response);
            }
        }, sleep);
    });
};

export const parseDateToDisplay = (date: string) => {
    if (date) {
        const [day, month, year] = date?.split('-');
        if (day && month && year) {
            return `${year}-${month}-${day}`;
        }
    }
    return null;
};

export const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
};

type ComplianceOptions = {
    isZeroInvalid?: boolean;
};

const defaultCompliance = (obj: any, options?: ComplianceOptions) => {
    return Object.keys(obj).map(c_key => {
        let isCompliant: boolean;
        const value = obj[c_key];
        const targetIsObject = c_key === 'name' || c_key === 'description';

        if (options?.isZeroInvalid) {
            // If isZeroInvalid is true, treat 0 as invalid (false)
            isCompliant = value !== 0 && Boolean(value);
        } else {
            // Otherwise, treat 0 as valid (true)
            isCompliant = value === 0 ? true : Boolean(value);
        }

        if (targetIsObject) {
            Object.keys(value).forEach(obj_key => {
                const bypass = obj_key === 'empty';
                if (bypass) {
                    isCompliant = false;
                } else {
                    isCompliant = Boolean(value[obj_key]);
                }
            });
        }

        return isCompliant;
    });
}

export const validateForm = (
    obj: any,
    setSteps: (state: any) => void,
    stepNum: number,
    requiredFields: string[],
    complianceOptions?: ComplianceOptions
) => {
    const enableStep = () => {
        setSteps(steps => {
            return steps.map(step => {
                if (step.number === stepNum) {
                    return {
                        ...step,
                        isDisabled: false,
                    };
                }
                return step;
            });
        });
    };

    const disableStep = () => {
        setSteps(steps => {
            return steps.map(step => {
                if (step.number === stepNum) {
                    return {
                        ...step,
                        isDisabled: true,
                    };
                }
                return step;
            });
        });
    };

    if (!requiredFields.length) return enableStep();

    const reducedObject = requiredFields.reduce((acc, key) => {
        if (obj.hasOwnProperty(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});

    // console.log(reducedObject);

    if (defaultCompliance(reducedObject, complianceOptions).every(v => v === true)) {
        enableStep();
    } else {
        disableStep();
    }
};

export const getProjectInfo = () => {
    const bypassTowards: 'selfcare' | 'medipet' | 'b2b' = 'selfcare';
    const projects = {
        selfcare: isSelfcare(),
        medipet: isMediPet(),
        b2b: isB2B(),
    };

    const currentProject: 'selfcare' | 'medipet' | 'b2b' = isLocalEnv()
        ? bypassTowards
        : (Object.keys(projects).find(project => Boolean(projects[project])) as
            | 'selfcare'
            | 'medipet'
            | 'b2b');
    const user = {
        isSelfcare: !userHasThisPermissionOnly([roles.PROVIDER]) && projects.selfcare,
        isB2B: !userHasThisPermissionOnly([roles.PROVIDER]) && projects.b2b,
        isMediPet: userHasPermission([roles.PROVIDER]) && projects.medipet,
    };

    return {
        projects,
        currentProject,
        user,
    };
};
