import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Styles from './Checkbox.module.scss';

const Checkbox = ({
    checked = false,
    theme = 'square',
    position = 'left',
    variant = 'dark',
    innerRef = null,
    id = undefined,
    label,
    name,
    value,
    onChange,
    disabled,
    labelChild = null,
    ...otherProps
}) => {
    const labelStyle = label ? classnames(Styles.label, checked ? Styles.checked : null) : null;
    const checkmarkStyle = checked
        ? classnames(
            Styles.checkmark,
            Styles.checked,
            Styles[theme],
            Styles[variant],
            Styles[position],
        )
        : classnames(Styles.checkmark, Styles[theme], Styles[variant], Styles[position]);

    return (
        <div
            id={id}
            ref={innerRef}
            data-test="Checkbox"
            className={classnames(Styles.checkbox, Styles[theme])}
        >
            <label
                data-test="CheckboxLabelTag"
                className={classnames(Styles.container, Styles[position])}
            >
                {labelChild ? (
                    labelChild
                ) : label ? (
                    <span data-test="CheckboxLabelText" className={classnames([labelStyle])}>
                        {label}
                    </span>
                ) : null}
                <input
                    data-test="CheckboxInput"
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    {...otherProps}
                />
                <span data-test="CheckboxCheckmark" className={checkmarkStyle}></span>
            </label>
        </div>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    theme: PropTypes.oneOf(['square', 'round', 'easy-rounded']),
    variant: PropTypes.oneOf(['light', 'dark']),
    position: PropTypes.oneOf(['right', 'left', 'center', 'none', 'rightWithLabel']),
    disabled: PropTypes.bool,
    checkboxRef: PropTypes.any,
};

export default Checkbox;
