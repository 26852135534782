import { ResultCode } from '@adyen/adyen-web';
import React, { useState } from 'react';

type EmittedVoucherResponse = {
    resultCode: ResultCode;
};

type CampaignContextType = {
    campaigns: {
        emittedVoucherResponse: EmittedVoucherResponse;
        setEmittedVoucherResponse: (voucher: EmittedVoucherResponse) => void;
    };
};

export const CampaignContext = React.createContext<CampaignContextType>(null);

const CampaignsProvider = ({ children }) => {
    const [emittedVoucherResponse, setEmittedVoucherResponse] = useState<EmittedVoucherResponse>();

    const initialValue = {
        campaigns: {
            emittedVoucherResponse,
            setEmittedVoucherResponse,
        },
    };

    return <CampaignContext.Provider value={initialValue}>{children}</CampaignContext.Provider>;
};

export default CampaignsProvider;
